import {
  TOKEN_KEY,
  GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY,
  GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY,
  GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY,
  COUPON_TOKEN_KEY,
  COUPON_BASE_URL_KEY,
} from "../../../base";
import { LoggedInUser } from "../../../domain/usages/auth/logged-in-user";
import { JsonStorage } from "../../protocols/storage/json-storage";

export class LocalLoggedInUser implements LoggedInUser {
  tokenKey: string = TOKEN_KEY;
  couponTokenKey: string = COUPON_TOKEN_KEY;
  couponBaseURLKey: string = COUPON_BASE_URL_KEY;
  productKey: string = GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY;
  productAuthKey: string = GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY;
  doctorAuthKey: string = GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY;

  constructor(private readonly jsonStorage: JsonStorage) {}

  getToken(): string | null {
    let token = this.jsonStorage.get(this.tokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setCouponToken(token: string): void {
    this.jsonStorage.add(this.couponTokenKey, this.encrypt(token));
  }

  getCouponToken(): string | null {
    let token = this.jsonStorage.get(this.couponTokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setToken(token: string): void {
    this.jsonStorage.add(this.tokenKey, this.encrypt(token));
  }

  getCODBaseURL(): string | null {
    let url = this.jsonStorage.get(this.couponBaseURLKey);
    if (url && url !== "") return url;
    return null;
  }

  setCODBaseURL(url: string): void {
    this.jsonStorage.add(this.couponBaseURLKey, url);
  }

  getProductToken(): string | null {
    let token = this.jsonStorage.get(this.productKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setProductToken(token: string): void {
    this.jsonStorage.add(this.productKey, this.encrypt(token));
  }

  getProductAuthToken(): string | null {
    let token = this.jsonStorage.get(this.productAuthKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setProductAuthToken(token: string): void {
    this.jsonStorage.add(this.productAuthKey, this.encrypt(token));
  }

  getDoctorAuthToken(): string | null {
    let token = this.jsonStorage.get(this.doctorAuthKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setDoctorAuthToken(token: string): void {
    this.jsonStorage.add(this.doctorAuthKey, this.encrypt(token));
  }

  logout(): void {
    this.jsonStorage.remove(this.tokenKey);
    this.jsonStorage.remove(this.productKey);
    this.jsonStorage.remove(this.productAuthKey);
    this.jsonStorage.remove(this.doctorAuthKey);
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(value: string) {
    return atob(value);
  }
}
