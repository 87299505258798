import * as React from "react";
import { Box, InputLabel, SxProps, TextField, Theme } from "@mui/material";

interface Props {
  id?: string;
  label?: string;
  placeholder: string;
  value: string | number;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  type?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  handleChange: any;
  fullWidth?: boolean;
  extraHelperText?: string;
  handleExtraHelperClick?: any;
  endAdorement?: any;
}

const CustomizedInputField: React.FC<Props> = ({
  label,
  id,
  placeholder,
  value,
  error,
  helperText,
  name,
  multiline,
  rows,
  type,
  required,
  sx,
  disabled,
  handleChange,
  endAdorement,
  fullWidth,

}) => {
  return (
    <Box mt={1} sx={{ flexGrow: 1 }}>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: "1rem",
        }}
        shrink
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <TextField
        InputProps={{
          style: {
            height: multiline ? "auto" : "48px",
            borderRadius: "0.3125rem",
            fontSize: "0.9375rem",
            boxShadow: "none",
          },
          endAdornment: endAdorement,
        }}
        fullWidth={fullWidth}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        id={id}
        rows={rows}
        multiline={multiline}
        error={error}
        name={name}
        helperText={helperText}
        sx={{
          "& ::placeholder": {
            color: "#242424",
            fontSize: "0.9375rem",
            fontWeight: "400",
          },

          ...sx,
        }}
        type={type ? type : "text"}
        disabled={disabled}
      />
    </Box>
  );
};
export default CustomizedInputField;
