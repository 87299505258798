import { Chip, Stack, TextField } from "@mui/material";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useCouponStore } from "../../../../store/main/CouponStore";

type FilterItem = {
  label: string;
  onClick: Function;
  icon?: React.ReactElement<any>;
};

type SortingOrder = "asc" | "default";

type Props = {
  filterItems: FilterItem[];
};

export const CouponFilters: React.FC<Props> = ({ filterItems }) => {
  const { handleAppliedFilters } = useCouponStore();
  const [value, setValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, SortingOrder>
  >({
    "Expires First": "default",
    InActive: "default",
  });

  const handleFilterClick = (label: string) => {
    setSelectedFilters((prevSelected) => {
      const currentOrder = prevSelected[label] || "default";
      let newOrder;
      newOrder = {
        default: "asc",
        asc: "default",
      }[currentOrder] as SortingOrder;
      console.log({ ...prevSelected, [label]: newOrder });
      handleAppliedFilters({ ...prevSelected, [label]: newOrder, q: value });
      return { ...prevSelected, [label]: newOrder };
    });
  };

  const handleSearch = (e: any) => {
    setValue(e.target.value);
    handleAppliedFilters({ ...selectedFilters, q: e.target.value });
  };

  const debouncedOnChange = debounce(handleSearch, 1000);
  return (
    <Stack gap={1}>
      <TextField
        InputProps={{
          style: {
            minWidth: 250,
            maxWidth: "80%",
            height: "36px",
            borderRadius: "4px",
            fontSize: "0.9375rem",
            boxShadow: "none",
          },
          startAdornment: <SearchIcon color="disabled" />,
        }}
        placeholder="Search...."
        onChange={debouncedOnChange}
      />
      <Stack direction={"row"} flexWrap="wrap" gap={1} width={"80%"}>
        {filterItems.map((data, index) => (
          <Chip
            icon={data.icon}
            sx={{ width: "100px", py: 2 }}
            key={index}
            size={"small"}
            label={data.label}
            color={
              selectedFilters[data.label] !== ("default" || undefined)
                ? "primary"
                : "default"
            }
            onClick={() => {
              handleFilterClick(data.label);
              data.onClick(selectedFilters);
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
