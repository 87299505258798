import create, { SetState } from 'zustand';

type Error = {
  id: string;
  error: boolean;
}[];

interface CommonStoreInterface {
  error: Error;
  setError: (id: string, newError: boolean) => void;
  updateError: (id: string, newError: boolean) => void;
  resetError: () => void;
}

const initialFilterPayload = {
  error: [] as Error,
};

export const useCommonStore = create<CommonStoreInterface>((set: SetState<CommonStoreInterface>) => ({
  ...initialFilterPayload,
  setError: (id: string, newError: boolean) =>
    set((state) => ({
      error: state.error.some((e) => e.id === id)
        ? state.error.filter((e) => e.id !== id)
        : [...state.error, { id, error: newError }],
    })),
    updateError: (id: string, newError: boolean) =>
    set((state) => ({
      error: state.error.some((e) => e.id === id)
        ? state.error.map((e) =>
        e.id === id ? { ...e, error: newError } : e
      )
        : [...state.error, { id, error: newError }],
    })),
    resetError: () =>
    set(() => ({
        error: initialFilterPayload.error,
    })),
}));
