import Box from "@mui/material/Box";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { useState } from "react";

type Props = {
  tableData: any[];
  columns: any[];
  limit: number;
  handleSelectCallback: (data: any) => void;
};

export const TableComponent: React.FC<Props> = ({
  tableData,
  columns,
  limit,
  handleSelectCallback,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    setSelectionModel(newSelectionModel);

    const selectedRows = newSelectionModel.map((selectedId) =>
      tableData.find((row) => row.id === selectedId)
    ) as any[];

    handleSelectCallback(selectedRows);
  };

  const strikeThroughStyle = { textDecoration: "line-through" };

  const columnsWithCustomRendering = columns.map((column) => {
    if (column.field === "price") {
      return {
        ...column,
        renderCell: (params: any) => (
          <div style={strikeThroughStyle}>{params.value}</div>
        ),
      };
    }
    return column;
  });

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={tableData}
        columns={columnsWithCustomRendering}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={() => {}}
        onRowSelectionModelChange={handleSelectionModelChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
};
