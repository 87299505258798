import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Typography } from "@mui/material";
import { Button } from "@mui/material";

type Props = {
  data: any;
  totalCount: number;
  handleSelectCallback: Function;
  selectedRows: any;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  page: number;
  rowsPerPage: number;
  handleOpenModal: (row: any) => void;
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box width={300} sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export const CustomCouponTable: React.FC<Props> = ({
  data,
  totalCount,
  handleSelectCallback,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  selectedRows,
  handleOpenModal,
}) => {
  const handleCheckboxChange = (row: any) => {
    const updatedSelectedRows: any = [...selectedRows];
    const index = updatedSelectedRows.findIndex((r: any) => r.id === row.id);
    if (index !== -1) {
      updatedSelectedRows.splice(index, 1);
    } else {
      updatedSelectedRows.push(row);
    }
    handleSelectCallback(updatedSelectedRows);
  };

  const handleSelectAllCheckboxChange = () => {
    if (selectedRows.length > 0) {
      handleSelectCallback([]);
    } else {
      if (data.every((row: any) => selectedRows.includes(row))) {
      } else {
        handleSelectCallback(data);
      }
    }
  };

  const isSelected = (row: any) =>
    selectedRows.some((r: any) => r.id === row.id);

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - data.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableHead>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                Object.values(selectedRows).length > 0 &&
                Object.values(selectedRows).length < data.length
              }
              checked={Object.values(selectedRows).length === data.length}
              onChange={handleSelectAllCheckboxChange}
            />
          </TableCell>
          <TableCell>Coupon Code</TableCell>
          <TableCell>Coupon Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Registration Date</TableCell>
          <TableCell>Discount</TableCell>
          <TableCell>Discount Type</TableCell>
          <TableCell>Products</TableCell>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(row)}
                  onChange={() => handleCheckboxChange(row)}
                />
              </TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.start_date}</TableCell>
              <TableCell>{row.discount}</TableCell>
              <TableCell>{row.discount_type}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    handleOpenModal(row);
                  }}
                >
                  <Typography textTransform={"none"}>products</Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
            <TableCell colSpan={1}>
              Rows Selected: {selectedRows.length}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
