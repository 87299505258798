import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  NoSsr,
  Stack,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import CODLogo from "../../../assets/CodLogo.svg";
import { grey } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { PageRoutes } from "../../../routes/PageRoutes";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/auth/local-logged-in-user";

const drawerData = [
  {
    link: "/coupon",
    title: "Coupons",
    icon: <CircleIcon />,
  },
  {
    link: "/doctor",
    title: "Doctors",
    icon: <ChangeHistoryOutlinedIcon />,
  },
];

export const SideLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpen(false);
    navigate(PageRoutes.LOGIN);
    loggedInUser.setToken("");
    loggedInUser.setProductToken("");
    loggedInUser.setProductAuthToken("");
    loggedInUser.setDoctorAuthToken("");
  };
  return (
    <NoSsr>
      <Stack
        sx={{
          background: "#fff !important",
          borderRight: "1px solid lightgrey",
        }}
      >
        <IconButton
          sx={{
            minHeight: 48,
            justifyContent: "center",
            px: 0,
            my: 1,
          }}
        >
          <img src={CODLogo} alt="COD Logo" />
        </IconButton>
        <List>
          <Stack alignItems={"center"}>
            {drawerData.map((data, index) => (
              <Tooltip key={index} title={data?.title} arrow placement="right">
                <ListItemButton
                  component={RouterLink}
                  to={data.link}
                  sx={{
                    minHeight: 45,
                    maxWidth: 54,
                    alignContent: "center",
                    textDecoration: "none",
                    backgroundColor:
                      location.pathname === data.link ? "#E9F7FF" : "white",
                    "&:hover": {
                      backgroundColor: grey["200"],
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {data?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={data?.title}
                    sx={{
                      opacity: 0,
                      fontSize: "0.875rem",
                      textDecoration: "none !important",
                      fontWeight: 700,
                      color: "white",
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            ))}
          </Stack>
          <Button onClick={() => setOpen(true)}>
            <Stack direction={"row"} spacing={1}>
              <LogoutIcon />
            </Stack>
          </Button>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Logout Confirmation</DialogTitle>
            <DialogContent>Are you sure you want to logout?</DialogContent>
            <DialogActions>
              <Button onClick={handleLogout} color="primary">
                Logout
              </Button>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </List>
      </Stack>
    </NoSsr>
  );
};
