import React from "react";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AddNewProductModal } from "./AddNewProductModal";
import { Dayjs } from "dayjs";

interface FormErrors {
  [key: string]: string | null;
}

interface FormValues {
  couponCode: string;
  couponName: string;
  couponDescription: string;
  value: string;
  percentage: string;
  minQty: string;
  startDate: Dayjs | null;
  expiryDate: Dayjs | null;
  agreeToTerms: boolean;
}

interface CreateCouponModelProps {
  onClose: () => void;
  formValues: FormValues;
  handleChange: (field: string, value: string | boolean) => void;
  errors: FormErrors;
  handleSubmit: (e: React.FormEvent) => void;
  handleCallBack: Function;
  valueField: boolean;
  percentageField: boolean;
  selectedProducts: any;
}

export const CreateCouponModel: React.FC<CreateCouponModelProps> = ({
  onClose,
  formValues,
  handleChange,
  errors,
  handleSubmit,
  handleCallBack,
  valueField,
  percentageField,
  selectedProducts,
}) => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Coupon Code"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                  }}
                  value={formValues.couponCode}
                  onChange={(e) => handleChange("couponCode", e.target.value)}
                  error={Boolean(errors.couponCode)}
                  helperText={errors.couponCode}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography mt={1}>Coupon Name</Typography>
                <TextField
                  label="Enter Coupon Name"
                  fullWidth
                  margin="normal"
                  value={formValues.couponName}
                  onChange={(e) => handleChange("couponName", e.target.value)}
                  error={Boolean(errors.couponName)}
                  helperText={errors.couponName}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography mt={1}>Coupon Description</Typography>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Enter Coupon Description"
                  multiline
                  rows={4}
                  margin="normal"
                  value={formValues.couponDescription}
                  onChange={(e) =>
                    handleChange("couponDescription", e.target.value)
                  }
                  error={Boolean(errors.couponDescription)}
                  helperText={errors.couponDescription}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography mt={1}>Value/Percentage</Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                  width="90%"
                >
                  <TextField
                    label="Value"
                    type="number"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>&#8377;</span>
                        </InputAdornment>
                      ),
                    }}
                    value={formValues.value}
                    onChange={(e) => handleChange("value", e.target.value)}
                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                      (e.target as HTMLInputElement).blur();
                    }}
                    error={!valueField && Boolean(errors.value)}
                    helperText={!valueField && errors.value}
                    disabled={valueField}
                  />

                  <Typography mr={2} ml={2}>
                    Or
                  </Typography>

                  <TextField
                    label="Percentage"
                    type="number"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    value={formValues.percentage}
                    onChange={(e) => handleChange("percentage", e.target.value)}
                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                      (e.target as HTMLInputElement).blur();
                    }}
                    error={!percentageField && Boolean(errors.percentage)}
                    helperText={!percentageField && errors.percentage}
                    disabled={percentageField}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography mt={1}>Minimum Quantity</Typography>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-multiline-static"
                  label="Enter Minimum Quantity"
                  margin="normal"
                  value={formValues.minQty}
                  onChange={(e) => handleChange("minQty", e.target.value)}
                  error={Boolean(errors.couponDescription)}
                  helperText={errors.couponDescription}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography mt={1}>Validity Period</Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                  width="90%"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      sx={{ width: "100% " }}
                      slotProps={{
                        textField: {
                          error: Boolean(errors.startDate),
                          helperText: errors.startDate,
                        },
                      }}
                      value={formValues.startDate}
                      onChange={(e: any) => handleChange("startDate", e)}
                    />
                  </LocalizationProvider>

                  <Typography mr={2} ml={2}>
                    To
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expiry Date"
                      sx={{ width: "100% " }}
                      slotProps={{
                        textField: {
                          error: Boolean(errors.expiryDate),
                          helperText: errors.expiryDate,
                        },
                      }}
                      value={formValues.expiryDate}
                      onChange={(e: any) => handleChange("expiryDate", e)}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.agreeToTerms}
                  onChange={() =>
                    handleChange("agreeToTerms", !formValues.agreeToTerms)
                  }
                  color="primary"
                />
              }
              label="Agree to Terms and Conditions"
            />

            <FormHelperText error={Boolean(errors.agreeToTerms)}>
              {errors.agreeToTerms}
            </FormHelperText>

            {selectedProducts.length > 0 && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "16px" }}
              >
                Submit
              </Button>
            )}
          </form>
        </Grid>
        <Grid item xs={6}>
          {formValues.value || formValues.percentage ? (
            <AddNewProductModal
              handleCallBack={(data: any) => {
                handleCallBack(data);
              }}
              discount_type={!valueField ? "flat" : "percentage"}
              discount={!valueField ? formValues.value : formValues.percentage}
            />
          ) : (
            <Typography>
              Please select organization and discount to select products
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
