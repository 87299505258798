import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { Endpoints } from "../../../domain/Endpoints";
import { RemoteUpdateRequestStatus } from "../../../data/usecases/discount-approvals/remote-update-request-status";
import Swal from "sweetalert2";
import { UpdateRequestType } from "../../../domain/models/discount-approvals/update-request-type";
import { useDiscountApprovalStore } from "../../main/DiscountApprovalStore";
import { AUTH_HEADER, BASE_API_URL, COUPON_BASE_URL_KEY, COUPON_TOKEN_KEY, DISCOUNT_COUPON_API_URL } from "../../../base";

interface UpdateRequestStatusSliceInterface {
  updateRequestStatus: Function;
  fetchUpdateRequestStatusState: FetchState;
  openConfirmModal: boolean;
  handleConfirmModal: Function;
  captureUpdateRequestData: UpdateRequestType;
  handleUpdateStatusLoading: Function;
}

const initialStates = {
  fetchUpdateRequestStatusState: FetchState.DEFAULT,
  openConfirmModal: false,
  captureUpdateRequestData: {} as UpdateRequestType,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateRequestStatusSlice: StateCreator<
  UpdateRequestStatusSliceInterface
> = (set, get) => ({
  ...initialStates,
  handleUpdateStatusLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },

        title: "Please wait... your request is processing ",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
  updateRequestStatus: async () => {
    const token = storage.get(COUPON_TOKEN_KEY);
    // const BASE_URL = storage.get(COUPON_BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteUpdateRequestStatus = new RemoteUpdateRequestStatus(
      `${DISCOUNT_COUPON_API_URL}${Endpoints.UPDATE_REQUEST_STATUS}`,
      axiosHttpClient
    );
    try {
      get().handleConfirmModal(false, get().captureUpdateRequestData);
      get().handleUpdateStatusLoading(true);
      let payload = {
        approved_discount: parseFloat(
          get().captureUpdateRequestData.selectedDiscount
        ),
        remark: get().captureUpdateRequestData.remarks,
      };

      let result = await remoteUpdateRequestStatus.update(
        get().captureUpdateRequestData.requestId.toString(),
        payload
      );
      if (result.success) {
        get().handleUpdateStatusLoading(false);
        set(() => ({
          fetchUpdateRequestStatusState: FetchState.SUCCESS,
        }));
        Swal.fire({
          icon: "success",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useDiscountApprovalStore
          .getState()
          .fetchCouponRequestForCustomer(
            get().captureUpdateRequestData.customerId,
            1,
            10
          );
      } else {
        get().handleUpdateStatusLoading(false);

        set(() => ({
          fetchUpdateRequestStatusState: FetchState.SUCCESS,
        }));
        Swal.fire({
          icon: "error",
          title: result.errors ? result.errors : "Something went wrong. Please contact support team",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    } catch {}
  },
  handleConfirmModal: (value: boolean, data: UpdateRequestType) => {
    set(() => ({
      captureUpdateRequestData: data,
      openConfirmModal: value,
    }));
  },
});
