import { create } from "zustand";
import { useRequestLoginOTP } from "../slices/auth/RequestOTPSlice";
import { useLoginSlice } from "../slices/auth/LoginSlice";

interface LoginStoreInterface
  extends ReturnType<typeof useRequestLoginOTP>,
    ReturnType<typeof useLoginSlice>{}

export const useLoginStore = create<LoginStoreInterface>((...data) => ({
  ...useRequestLoginOTP(...data),
  ...useLoginSlice(...data),
}));
