import Skeleton from "@mui/material/Skeleton";
import { Stack, Card } from "@mui/material";

export default function InfoSkeleton() {
  return (
    <Card>
      <Stack spacing={3}>
        <Skeleton variant="rectangular" height={32} width={210} />

        <Stack justifyContent={"space-around"} mb={3} spacing={2}>
          <Skeleton variant="rectangular" height={64} />
          <Skeleton variant="rectangular" height={64} />
          <Skeleton variant="rectangular" height={64} />
          <Skeleton variant="rectangular" height={64} />
        </Stack>

        <Skeleton variant="rectangular" height={210} />
      </Stack>
    </Card>
  );
}
