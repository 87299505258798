import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { DeleteCustomers } from "../../../domain/usages/coupons/delete-customers";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpDeleteClient } from "../../protocols/http/http-delete-client";

export class RemoteDeleteCustomers implements DeleteCustomers {
  constructor(
    private readonly url: string,
    private readonly HttpDeleteClient: HttpDeleteClient
  ) {}

  async remove(id:string, params?: DeleteCustomers.Params): Promise<any> {
    const httpResponse = await this.HttpDeleteClient.delete({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
