import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import { useDoctorStore } from "../../main/DoctorStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteDeleteCustomers } from "../../../data/usecases/coupons/remote-delete-customers";
import Swal from 'sweetalert2'

interface DeleteCustomersInterface {
    deleteCustomers: Function;
    deleteState: FetchState;
    loadingCustomerDeletion: boolean;
}

const initialStates = {
    deleteState: FetchState.DEFAULT,
    loadingCustomerDeletion: false,
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();


export const useDeleteCustomersSlice: StateCreator<
DeleteCustomersInterface
> = (set, get) => ({
  ...initialStates,

  deleteCustomers: async (id: number, customer_uuids: string[]) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({ })
    const remoteDeleteCustomers = new RemoteDeleteCustomers(
      `${BASE_API_URL}${Endpoints.REMOVE_COUPON_CUSTOMERS}`,
      axiosHttpClient
    );
    set(() => ({
        loadingCustomerDeletion: true,
      }));

    const payload = {
      customer_ids: customer_uuids
    };
    try {
      let result = await remoteDeleteCustomers.remove(id.toString(), payload);
      if (result.success) {
        set(() => ({ loadingCustomerDeletion: false }));
        useCouponStore.getState().fetchCouponCustomerList(id)
        useDoctorStore.getState().fetchDoctorCouponsList(useDoctorStore.getState().selectedDoctor._id);
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });   
      }
      else{
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    } catch (err) {
        set(() => ({ loadingCustomerDeletion: false }));
  }
}
  
});
