import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: Function;
  handleAction?: Function;
  primaryButtonText: string;
  modalContent: React.ReactNode;
  title: string;
};

export const ViewModal: React.FC<Props> = ({
  primaryButtonText,
  onClose,
  open,
  modalContent,
  title,
  handleAction,
}) => {
  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>
        <Typography variant="h6" fontWeight={600}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="grey">
          {modalContent}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "start", padding: 3 }}
      >
        {primaryButtonText && (
          <Button
            color="warning"
            onClick={() => {
              onClose();
            }}
          >
            <Typography textTransform={"uppercase"} color={"black"}>
              {primaryButtonText}
            </Typography>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
