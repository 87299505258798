import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { AddCustomers } from "../../../domain/usages/coupons/add-customers";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteAddCustomers implements AddCustomers {
  constructor(
    private readonly url: string,
    private readonly HttpPostClient: HttpPostClient
  ) {}

  async add(id:string, params?: AddCustomers.Params): Promise<any> {
    const httpResponse = await this.HttpPostClient.post({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
