export const Endpoints={
    //Login page
    REQUEST_LOGIN_OTP:"/v1/auth/requestOtp",
    VERIFY_LOGIN:"/v1/auth/verifyOtp",

    //Coupon page
    FETCH_COUPONS_LIST: "/v1/coupons?length=:length&page=:page",
    CREATE_COUPON: "/v1/coupons",
    ADD_COUPON_CUSTOMER: "/v1/coupons/:id/customers",
    FETCH_COUPON_CUSTOMERS: "/v1/coupons/:id/customers",
    ADD_PRODUCT_COUPON: "/v1/coupons/:id/products",
    REMOVE_COUPON_CUSTOMERS: "/v1/coupons/:id/customers",
    REMOVE_PRODUCT_COUPON: "/v1/coupons/:id/products",
    APPLY_COUPON: "/v1/coupons/apply",
    // FETCH_PRODUCTS_LIST: "/v1/products?display_category=:display_category&category=:category&length=:length&page=:page",
    FETCH_PRODUCTS_LIST: "/v1/commercials/products?page=:page&length=:length",  // &org_code=GOAPPTIV, product_group_ids[]=65cf26313e6e7f5a9d5b18d5  div_code=GA_WELLNESS
    DEACTIVATE_COUPON: "/v1/coupons/:id/deactivate",

    //Doctors page
    FETCH_DOCTORS_LIST: "/v1/doctors?length=:length&page=:page",
    FETCH_DOCTOR_COUPONS_LIST: "/v1/coupons",  

    //common
    GET_DEPARTMENTS_LIST: "/v2/departments",
    GET_ORGANIZATIONS_LIST: "/v2/organizations",
    GET_HEADQUARTERS_LIST: "/v2/headquarters",

    //DISCOUNT APPROVALS
    COUPON_REQUEST_FOR_HEADQUARTER:"/v1/coupons/requests/customers",
    COUPON_REQUEST_FOR_CUSTOMER:"/v1/coupons/requests/products/for-customer/:id",
    UPDATE_REQUEST_STATUS: "/v1/coupons/requests/product/:id/approve"

};