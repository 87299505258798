import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Layout } from "../components/LayoutComponents/Layout";
import { DoctorListing } from "../components/UiComponents/DoctorComponents/DoctorListing";
import { TabComponent } from "../components/Common/TabComponent/TabComponent";
import { DoctorInformation } from "../components/UiComponents/DoctorComponents/DoctorInformation";
import { CouponsList } from "../components/UiComponents/DoctorComponents/CouponsList";
import { useDoctorStore } from "../../store/main/DoctorStore";
import { useCouponStore } from "../../store/main/CouponStore";
import { DoctorFilter } from "../components/UiComponents/DoctorComponents/DoctorFilters";

export const DoctorPage = () => {
  useEffect(() => {
    useDoctorStore
      .getState()
      .fetchDoctorsList(1, 10, false, useDoctorStore.getState().doctorFilters);
    useCouponStore.getState().filter = {
      q: "",
      expire_date: "",
    };
    useDoctorStore.getState().doctorFilters = {
      q: "",
      hq_code: "",
    };
  }, []);

  return (
    <Layout>
      <Grid container m={1}>
        <Grid item xs={3}>
          <Stack gap={3}>
            <Typography variant="h5" fontWeight={500}>
              Doctors Overview
            </Typography>
            <DoctorFilter />
            <Box sx={{ overflowY: "scroll" }} height="47vh" id="doctor-page">
              <DoctorListing />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={9}
          paddingTop={7}
          paddingLeft={2}
          sx={{
            overflowY: "scroll",
            height: "93vh",
            backgroundColor: "#f9f9f9",
          }}
        >
          <TabComponent
            tabs={[
              {
                label: "Doctor Information",
                content: <DoctorInformation />,
              },
              {
                label: "List of Coupons",
                content: <CouponsList />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
