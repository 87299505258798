import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FetchDoctorsList } from "../../../domain/usages/doctors/fetch-doctors-list";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";
import {PageRoutes} from "../../../routes/PageRoutes"
import {LocalJsonStorage} from '../../../infra/http/local-json-storage'
import {LocalLoggedInUser} from '../auth/local-logged-in-user'

export class RemoteFetchDoctorsList implements FetchDoctorsList {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(length:number,page:number,params: FetchDoctorsList.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":length", length.toString()).replace(":page", page.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }else if(httpResponse.status == HTTPStatusCode.FORBIDDEN || httpResponse.status==HTTPStatusCode.UNAUTHORIZED){
      const storage = LocalJsonStorage.getInstance();
      const loggedInUser = new LocalLoggedInUser(storage);
      loggedInUser.setToken("");
      loggedInUser.setProductToken("");
      loggedInUser.setProductAuthToken("");
      loggedInUser.setDoctorAuthToken("");
      window.location.href = PageRoutes.LOGIN;
    }
  }
}
