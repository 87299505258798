import { Button, Stack, Typography } from "@mui/material";
import { HeaderText } from "../../Common/Typography/HearderText";
import CustomizedInputField from "../../Common/InputComponents/InputField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TableComponent } from "../../Common/Table/TableComponent";
import { useState } from "react";
import { ConfirmationModal } from "../../Common/Modal/ConfirmationModal";
import { LoadingModal } from "../../Common/Modal/LoadingModal";
import { useCouponStore } from "../../../../store/main/CouponStore";
import InfoSkeleton from "../../Common/Skeleton/InfoSkeleton";
import moment from "moment";
import { Constants } from "../../../../common/Constants";
import { GridColDef } from "@mui/x-data-grid";
import { FetchState } from "../../../../domain/models/fetch-state-type";

const productsColumns: GridColDef[] = [
  { field: "name", headerName: "Product Name", flex: 1 },
  { field: "price", headerName: "Price", flex: 1 },
  { field: "final_discount", headerName: "Discounted Price", flex: 1 },
];

export const CouponInformation = () => {
  const {
    selectedCoupon,
    fetchState,
    deactivateCoupon,
    deactivateCouponFetchState,
  } = useCouponStore();
  const [openDeactivateCouponModal, setOpenDeactivateCouponModal] =
    useState<boolean>(false);

  const discountCalculatedProducts = (products: any) => {
    return products.map((product: any) => {
      let final_discount;
      if (selectedCoupon.discount_type === "percentage") {
        final_discount =
          parseFloat(product.price) *
          ((100 - parseFloat(selectedCoupon.discount)) / 100);
      } else if (selectedCoupon.discount_type === "flat") {
        final_discount =
          parseFloat(product.price) - parseFloat(selectedCoupon.discount);
      } else {
        final_discount = 0.0;
      }
      final_discount = parseFloat(final_discount.toFixed(2));
      return { ...product, final_discount: final_discount };
    });
  };

  return (
    <div>
      {fetchState === FetchState.LOADING && <InfoSkeleton />}
      {fetchState === FetchState.SUCCESS && (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HeaderText title={selectedCoupon.name} />
            {selectedCoupon.status === "active" ? (
              <Stack
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"35%"}
                flexDirection={"row"}
              >
                <Typography color={"primary"} sx={{ display: "flex", gap: 1 }}>
                  <CheckCircleOutlineIcon />
                  <span>Status: {selectedCoupon.status}</span>
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setOpenDeactivateCouponModal(true)}
                >
                  DeActivate
                </Button>
              </Stack>
            ) : (
              <Typography color={"primary"} sx={{ display: "flex", gap: 1 }}>
                <CheckCircleOutlineIcon />
                <span>Status: {selectedCoupon.status}</span>
              </Typography>
            )}
          </Stack>
          <Stack justifyContent={"space-around"} mb={3} spacing={2}>
            <Stack direction={"row"}>
              <CustomizedInputField
                placeholder={""}
                value={selectedCoupon.code}
                handleChange={() => {}}
                label="Coupon Code"
              />
              <CustomizedInputField
                placeholder={""}
                value={moment(selectedCoupon.start_date).format(
                  Constants.MOMENT_MONTH_DATE_YEAR
                )}
                handleChange={() => {}}
                label="Registration Date"
              />
              <CustomizedInputField
                placeholder={""}
                value={moment(selectedCoupon.expire_date).format(
                  Constants.MOMENT_MONTH_DATE_YEAR
                )}
                handleChange={() => {}}
                label="Expiration Date"
              />
            </Stack>
            <Stack direction={"row"}>
              <Stack direction={"column"} spacing={2}>
                <CustomizedInputField
                  fullWidth
                  placeholder={""}
                  value={selectedCoupon.description}
                  handleChange={() => {}}
                  label="Description"
                />
                <Stack direction={"row"} spacing={2}>
                  <CustomizedInputField
                    placeholder={""}
                    value={selectedCoupon.discount_type}
                    handleChange={() => {}}
                    label="Discount Type"
                  />
                  <CustomizedInputField
                    placeholder={""}
                    value={selectedCoupon.discount}
                    handleChange={() => {}}
                    label="Discount Value"
                  />
                </Stack>
                <CustomizedInputField
                  fullWidth
                  placeholder={""}
                  value={selectedCoupon.min_qty}
                  handleChange={() => {}}
                  label="Minimum Quantity"
                />
              </Stack>
            </Stack>
          </Stack>
          {selectedCoupon.products && (
            <TableComponent
              tableData={discountCalculatedProducts(selectedCoupon.products)}
              columns={productsColumns}
              limit={5}
              handleSelectCallback={() => {}}
            />
          )}
        </>
      )}
      {fetchState === FetchState.ERROR && (
        <Typography>Something went wrong</Typography>
      )}

      {openDeactivateCouponModal && (
        <ConfirmationModal
          title={"Delete selected doctors?"}
          open={openDeactivateCouponModal}
          onClose={() => {
            setOpenDeactivateCouponModal(false);
          }}
          handleAction={() => {
            deactivateCoupon(selectedCoupon.id);
            setOpenDeactivateCouponModal(false);
          }}
          primaryButtonText={"Confirm Deactivate"}
          secondaryButtonText={"Cancel"}
          modalContent={`Please confirm to deactivate the selected coupon - #${selectedCoupon.code}`}
        />
      )}
      {deactivateCouponFetchState === FetchState.LOADING && (
        <LoadingModal
          open={deactivateCouponFetchState === FetchState.LOADING}
          title={"Loading..."}
          modalContent={"Please wait while your request is proccesing"}
        />
      )}
    </div>
  );
};
