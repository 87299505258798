import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {Endpoints} from "../../../domain/Endpoints";
import { RequestLoginOtp } from "../../../domain/usages/auth/request-login-otp";
import { STAGIN_AUTH_URL } from "../../../base";
import Swal from "sweetalert2";
import { RemoteRequestLoginOtp } from "../../../data/usecases/auth/remote-request-login-otp";
import { useCouponStore } from "../../main/CouponStore";

interface RequestOtpInterface {
  requestOtp: Function;
  requestOtploading: boolean;
  confirmOtpSent: Function;
  otpSent: boolean;
}

const initialState = {
  requestOtploading: false,
  otpSent: false,
};

export const useRequestLoginOTP: StateCreator<RequestOtpInterface> = (set, get) => {
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    confirmOtpSent:(value:boolean)=>{
      set(() => ({
        otpSent: value,
      }));

    },
    requestOtp: async (mobile: string) => {
      const requestLoginOtp: RequestLoginOtp = new RemoteRequestLoginOtp(
        `${STAGIN_AUTH_URL}${Endpoints.REQUEST_LOGIN_OTP}`,
        axiosHttpClient
      );
      set(() => ({
        requestOtploading: true,
      }));
      let payload = {
        mobile: mobile
      };
      useCouponStore.getState().getDepartmentsList()
      let result = await requestLoginOtp.requestOtp(payload);
      if (result.success) {
        set(() => ({
          requestOtploading: false,
        }));
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
        });
        get().confirmOtpSent(true)
      } else {
        set(() => ({
          requestOtploading: false,
        }));
        if(result.errors){
          Swal.fire(result.errors.message, "", "error");
        } else{
          Swal.fire(result.message, "", "error");
        }
      }
    },
  };
  
};
