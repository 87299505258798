import { Card, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  imageLink?: string;
  subtitle: string | number;
  startDate?: string;
  expiryDate?: string;
  onClick: Function;
  sx?: any;
};

export const ListingCard: React.FC<Props> = ({
  title,
  startDate,
  expiryDate,
  imageLink,
  subtitle,
  onClick,
  sx,
}) => {
  return (
    <Card
      sx={{
        minWidth: 200,
        maxWidth: "80%",
        borderRadius: "12px",
        padding: 1,
        boxShadow: "none",
        border: "1px solid #D0CFCF",
        cursor: "pointer",
        ...sx,
      }}
      onClick={() => onClick()}
    >
      <Stack direction={"row"} gap={3}>
        <Typography variant="body1" fontWeight={500} gutterBottom>
          {title}
        </Typography>
        {imageLink && <img width={50} height={20} src={imageLink} alt="img" />}
      </Stack>
      <Stack>
        <Typography variant="caption">{subtitle}</Typography>
        {startDate && (
          <Typography variant="caption">
            {startDate} - {expiryDate}
          </Typography>
        )}
      </Stack>
    </Card>
  );
};
