import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { DepartmentsList } from "../../../domain/models/common/get-departments-list";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { GA_AUTH_HEADER,STAGIN_AUTH_URL } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteGetDepartmentsList } from "../../../data/usecases/common/remote-get-departments-list";

interface GetDepartmentsListSliceInterface {
  getDepartmentsList: Function;
  departmentsList: DepartmentsList["departments"]
  fetchDepartmentsState: FetchState;
}


const initialStates = {
  fetchDepartmentsState: FetchState.DEFAULT,
  departmentsList: [] as DepartmentsList["departments"]
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useGetDepartmentsListSlice: StateCreator<
  GetDepartmentsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  getDepartmentsList: async () => {
    axiosHttpClient.setAuthHeaders({ [GA_AUTH_HEADER]: "eyJhbGciOiJNRDUiLCJ0eXBlIjoiSldUIn0=.eyJ1c2VyX2lkIjo0MTM5LCJvcmdfaWQiOjIsIm9yZ19jb2RlIjoiQ0lQUkVTIiwiZGVwdF9jb2RlIjoiQ0lQUkVTQ09QRCIsImRlcGFydG1lbnRfaWQiOjcsInJvbGUiOiJITyBBZG1pbiIsInJvbGVfaWQiOjEsImZ1bGxfbmFtZSI6IlJlc3BpIEFkbWluIn0=.ab652c3a4a9bd2eff527fdb4e3a9a6ea"})
    axiosHttpClient.setAuthHeaders1({})
    const remoteGetDepartmentsList = new RemoteGetDepartmentsList(
      `${STAGIN_AUTH_URL}${Endpoints.GET_DEPARTMENTS_LIST}`,
      axiosHttpClient
    );
    
    try {
        set(() => ({ fetchDepartmentsState: FetchState.LOADING }));
      let result = await remoteGetDepartmentsList.get();
      if (result.success) {
        set(()=>({fetchDepartmentsState:FetchState.SUCCESS}))
        set(()=>({departmentsList: result.departments}))
      }
    } catch (err) {
     set(() => ({ fetchDepartmentsState: FetchState.ERROR }));
    }
  },
});
