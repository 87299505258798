import { Grid } from "@mui/material";
import LoginForm from "./LoginForm";
import LoginImage from "../../../../assets/image969.svg";

export const LoginComponent = () => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={7}
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "50px" }}
        >
          <LoginForm />
        </Grid>
        <Grid item xs={5}>
          <img
            width={"100%"}
            height={"100%"}
            src={LoginImage}
            alt="image with a doctor"
          />
        </Grid>
      </Grid>
    </>
  );
};
