import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  COUPON_TOKEN_KEY,
  COUPON_BASE_URL_KEY,
  DISCOUNT_COUPON_API_URL,
} from "../../../base";
import { Endpoints } from "../../../domain/Endpoints";
import { FetchEntityType } from "../../../domain/models/discount-approvals/fetch-entity-type";
import { RemoteFetchEntities } from "../../../data/usecases/discount-approvals/remote-fetch-entities";

export type EntityListFiltersType = {
  q: string;
  is_approval_required:number,
};

interface FetchEntityListSliceInterface {
  fetchEntityList: Function;
  handleRemoveRecord: Function;
  fetchEntityListState: FetchState;
  entityList: FetchEntityType[];
  entityListCurrentPage: number;
  entityListLastPage: number;
  totalPages: number;
  entityListFilters: EntityListFiltersType;
  handlePageNumber: Function;
}

let initialFilterPayload = {
  q: "",
  is_approval_required:1,
};

const initialStates = {
  fetchEntityListState: FetchState.DEFAULT,
  entityListCurrentPage: 0,
  entityListLastPage: 0,
  totalPages: 0,
  entityList: [],
  entityListFilters: initialFilterPayload,
  rowsPerPage: 10,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchEntityListSlice: StateCreator<
FetchEntityListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchEntityList: async (page: number, length: number) => {
    const token = storage.get(
      COUPON_TOKEN_KEY
    );
    // const BASE_URL = storage.get(COUPON_BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]:atob(token)
    });
    axiosHttpClient.setAuthHeaders1({});
    const remoteFetchEntities = new RemoteFetchEntities(
      `${DISCOUNT_COUPON_API_URL}${Endpoints.COUPON_REQUEST_FOR_HEADQUARTER}`,
      axiosHttpClient
    );
    try {
      let params = {
        page: page,
        length: length,
        is_approval_required:get().entityListFilters.is_approval_required,
        q: get().entityListFilters.q

      };
      set(() => ({ fetchEntityListState: FetchState.LOADING }));
      let result = await remoteFetchEntities.fetch(params);
      if (result) {
        set(() => ({
          fetchEntityListState: FetchState.SUCCESS,
          entityListCurrentPage: result.current_page,
          entityListLastPage: Math.ceil(result.last_page),
          totalPages: result.total,
          entityList: result.data,
        }));
      }
    } catch (err) {
      set(() => ({ fetchEntityListState: FetchState.ERROR }));
    }
  },
  handlePageNumber: (pageNo: number) => {
    set(() => ({ entityListCurrentPage: pageNo }));
  },
  handleRemoveRecord:(id:string)=>{
    const updatedEntityList = get().entityList.filter(entity => entity.customer_id !== id);
    set(() => ({ entityList: updatedEntityList }));

  }
});
