import { create } from "zustand";
import { useFetchDoctorListSlice } from "../slices/Doctors/FetchDoctorsListSlice";
import { useFetchDoctorCouponsListSlice } from "../slices/Doctors/FetchDoctorCouponsSlice";


interface CouponStoreType
  extends ReturnType<typeof useFetchDoctorListSlice>,
          ReturnType<typeof useFetchDoctorCouponsListSlice>{}

export const useDoctorStore =
  create<CouponStoreType>((...doctor) => ({
    ...useFetchDoctorListSlice(...doctor),
    ...useFetchDoctorCouponsListSlice(...doctor),
  }));
