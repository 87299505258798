import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography, Box } from "@mui/material";
import { useLoginStore } from "../../../../store/main/LoginStore";
import { Controller, useForm } from "react-hook-form";
import { LoginFormFields } from "../../../../domain/models/auth/loginFormFields";
import { Constants } from "../../../../common/Constants";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../../routes/PageRoutes";
import Swal from "sweetalert2";
import { useEffect } from "react";
import ConnectOdLogo from "../../../../assets/image 968.svg";

type Props = {};

const LoginForm = (props: Props) => {
  const {
    requestOtp,
    requestOtploading,
    otpSent,
    loginLoading,
    submitLogin,
    confirmOtpSent,
  } = useLoginStore();
  const { handleSubmit, control, getValues } = useForm<LoginFormFields>({
    mode: "onChange",
  });

  const navigate = useNavigate();

  const handleRequestOtp = (data: LoginFormFields) => {
    requestOtp(data.mobile);
  };

  const handleLogin = (data: LoginFormFields) => {
    submitLogin(data, handleNavigation);
  };

  const handleNavigation = (login: boolean, message: string) => {
    if (login) {
      navigate(PageRoutes.COUPON_PAGE);
      Swal.fire({
        icon: "success",
        title: message,
        timer: 2000,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  };

  useEffect(() => {
    confirmOtpSent(false);
  }, []);

  return (
    <Stack spacing={2} alignItems={"center"}>
      <Stack spacing={2} alignItems={"center"}>
        {" "}
        <Box textAlign={"center"} mb={6}>
          <img width={300} src={ConnectOdLogo} alt="Connect On Demand Logo" />
        </Box>
        <Typography variant="h3" color={"primary"} fontWeight={700}>
          Login
        </Typography>
      </Stack>
      <Stack width={"50%"}>
        <Typography fontWeight={550}>Mobile Number:</Typography>
        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="number"
              size="small"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: { value: true, message: "Field Required" },
            pattern: {
              value: Constants.MOBILE_REGEX,
              message: Constants.MOBILE_CHECK,
            },
          }}
        />
      </Stack>
      {otpSent && (
        <Stack width={"50%"}>
          <Typography fontWeight={550}>OTP:</Typography>
          <Controller
            name="otp"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="number"
                size="small"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
        </Stack>
      )}

      {otpSent ? (
        <LoadingButton
          sx={{
            width: "50%",
          }}
          loading={loginLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleLogin)}
        >
          Login
        </LoadingButton>
      ) : (
        <LoadingButton
          sx={{
            width: "50%",
          }}
          loading={requestOtploading}
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleRequestOtp)}
        >
          Request OTP
        </LoadingButton>
      )}
    </Stack>
  );
};

export default LoginForm;
