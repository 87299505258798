import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  open: boolean;
  modalContent: string;
  title: string;
};

export const LoadingModal: React.FC<Props> = ({
  open,
  modalContent,
  title,
}) => {
  return (
    <Dialog open={open} sx={{ textAlign: "center" }}>
      <DialogTitle>
        <Typography variant="h6" fontWeight={600}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="grey">
          {modalContent}
        </Typography>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};
