import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Product } from "../../../domain/usages/common/product";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import {Endpoints} from "../../../domain/Endpoints";
import Swal from 'sweetalert2'
import { RemoteDeactivateCoupon } from "../../../data/usecases/coupons/remote-deactivate-coupon";

interface DeactivateCouponInterface {
    deactivateCoupon: Function;
    deactivateCouponFetchState: FetchState;
}

const initialStates = {
    deactivateCouponFetchState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDeactivateCouponSlice: StateCreator<
DeactivateCouponInterface
> = (set, get) => ({
  ...initialStates,
  deactivateCoupon: async (id:number) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteDeactivateCoupon = new RemoteDeactivateCoupon(
      `${BASE_API_URL}${Endpoints.DEACTIVATE_COUPON}`,
      axiosHttpClient
    );
    set(() => ({
        deactivateCouponFetchState: FetchState.LOADING,
      }));
    try {
      let result = await remoteDeactivateCoupon.deactivate(id);
      if (result.success) {
        set(() => ({ deactivateCouponFetchState: FetchState.SUCCESS }));
        Swal.fire({
          icon: "success",
          title: "Coupon deactivated successfully",
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useCouponStore.getState().fetchCouponsList(1,10,false,useCouponStore.getState().filter);
      }else if(result.errors){
        set(() => ({ deactivateCouponFetchState: FetchState.ERROR }));
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
      
    } catch (err) {
        set(() => ({ deactivateCouponFetchState: FetchState.ERROR }));
  }
}
  
});
