import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { fetchDoctorCoupons } from "../../../domain/models/doctors/fetch-doctor-coupons";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteFetchDoctorCouponsList } from "../../../data/usecases/doctors/remote-fetch-doctor-coupons";

interface FetchDoctorCouponsListSliceInterface {
  fetchDoctorCouponsList: Function;
  fetchDoctorCouponsState: FetchState;
  doctorCouponsList: fetchDoctorCoupons["coupons"];
}

const initialStates = {
  fetchDoctorCouponsState: FetchState.DEFAULT,
  doctorCouponsList: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDoctorCouponsListSlice: StateCreator<
  FetchDoctorCouponsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchDoctorCouponsList: async (id:string) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteFetchCouponCustomersDetails = new RemoteFetchDoctorCouponsList(
      `${BASE_API_URL}${Endpoints.FETCH_DOCTOR_COUPONS_LIST}`,
      axiosHttpClient
    );
    const payload={
        customer_uuids:[id]
    }
      if(id){
        set(()=>({fetchDoctorCouponsState: FetchState.LOADING}))
        let result = await remoteFetchCouponCustomersDetails.fetch(payload);
        if (result.success) {
            set(() => ({ doctorCouponsList: result.coupons, fetchDoctorCouponsState: FetchState.SUCCESS }));
        }else{
          set(()=>({fetchDoctorCouponsState: FetchState.ERROR}))
        }
      }        
  },
});
