import { Box, Stack, Typography } from "@mui/material";
import { ListingCard } from "../../Common/Cards/ListingCard";
import SkeletonCard from "../../Common/Skeleton/CardSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { FetchState } from "../../../../domain/models/fetch-state-type";

export const DoctorListing = () => {
  const {
    fetchDoctorListState,
    doctorsList,
    selectedDoctor,
    onSelectedDoctor,
    currentDoctorPage,
    lastDoctorPage,
    fetchDoctorsList,
    doctorFilters,
  } = useDoctorStore();

  let loadingOccurences = [1, 2, 3, 4];
  console.log(doctorsList);

  const hasMoreData = () => {
    return fetchDoctorListState == FetchState.SUCCESS
      ? currentDoctorPage < lastDoctorPage
      : false;
  };

  const renderContent = () => {
    if (fetchDoctorListState === "LOADING") {
      return loadingOccurences.map((val) => (
        <div key={val}>
          <SkeletonCard />
          <br />
        </div>
      ));
    } else if (fetchDoctorListState === "SUCCESS") {
      return doctorsList.map((doctor, index) => (
        <ListingCard
          sx={{
            bgcolor: selectedDoctor?._id === doctor._id ? "#E9F7FF" : "white",
          }}
          onClick={() => {
            onSelectedDoctor(index);
          }}
          key={index}
          title={doctor.full_name}
          subtitle={doctor.msl_code}
        ></ListingCard>
      ));
    }

    return (
      <>
        <p>something went wrong</p>
      </>
    );
  };

  return (
    <Box>
      <InfiniteScroll
        scrollableTarget="doctor-page"
        dataLength={doctorsList && doctorsList.length}
        next={() => {
          fetchDoctorsList(currentDoctorPage + 1, 10, true, doctorFilters);
        }}
        hasMore={hasMoreData()}
        loader={<ListLoader loadingOccurences={loadingOccurences} />}
        endMessage={<ListEndMessage />}
      >
        <Stack gap={1}>{renderContent()}</Stack>
      </InfiniteScroll>
    </Box>
  );
};

const ListLoader: React.FC<{ loadingOccurences: number[] }> = ({
  loadingOccurences,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="body2">Fetching more products... ✋</Typography>
      {loadingOccurences.map((val) => (
        <SkeletonCard key={val} />
      ))}
    </div>
  );
};

export const ListEndMessage: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography align="center" variant="body2">
        That's it for now... 😀
      </Typography>
    </div>
  );
};
