import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Props = {
  names: any[];
  onSelectCallback?: (e: Name[]) => void;
  width: number;
};

type Name = {
  id: number;
  name: string;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultipleSelectCheckmarks: React.FC<Props> = ({
  names,
  onSelectCallback,
  width,
}) => {
  const [selectedNames, setSelectedNames] = React.useState<Name[]>([
    { id: 0, name: "" },
  ]);

  const handleChange = (event: any, newValue: Name[]) => {
    setSelectedNames(newValue);
    if (onSelectCallback) {
      onSelectCallback(newValue);
    }
  };

  return (
    <Autocomplete
      id="checkboxes-tags-demo"
      options={names}
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: width }}
      renderInput={(params) => (
        <TextField {...params} label="Headquartes" placeholder="Headquartes" />
      )}
    />
  );
};
