import { Box, Stack, Typography } from "@mui/material";
import { ListingCard } from "../../Common/Cards/ListingCard";
import SkeletonCard from "../../Common/Skeleton/CardSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCouponStore } from "../../../../store/main/CouponStore";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import moment from "moment";
import { Constants } from "../../../../common/Constants";

export const CouponListing = () => {
  const {
    fetchState,
    couponList,
    selectedCoupon,
    onSelectedCoupon,
    currentPage,
    lastPage,
    fetchCouponsList,
    filter,
  } = useCouponStore();

  let loadingOccurences = [1, 2, 3, 4];

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };

  const renderContent = () => {
    if (fetchState === "LOADING") {
      return loadingOccurences.map((val) => (
        <div key={val}>
          <SkeletonCard />
          <br />
        </div>
      ));
    } else if (fetchState === "SUCCESS") {
      return couponList.map((coupon, index) => (
        <ListingCard
          sx={{
            bgcolor: selectedCoupon?.id === coupon.id ? "#E9F7FF" : "white",
          }}
          onClick={() => {
            onSelectedCoupon(index);
          }}
          key={index}
          title={coupon.name}
          subtitle={coupon.code}
          startDate={moment(coupon.start_date).format(
            Constants.MOMENT_MONTH_DATE_YEAR
          )}
          expiryDate={moment(coupon.expire_date).format(
            Constants.MOMENT_MONTH_DATE_YEAR
          )}
        ></ListingCard>
      ));
    }

    return (
      <>
        <p>something went wrong</p>
      </>
    );
  };

  return (
    <Box>
      <InfiniteScroll
        scrollableTarget="coupon-page"
        dataLength={couponList && couponList.length}
        next={() => {
          fetchCouponsList(currentPage + 1, 10, true, filter);
        }}
        hasMore={hasMoreData()}
        loader={<ListLoader loadingOccurences={loadingOccurences} />}
        endMessage={<ListEndMessage />}
      >
        <Stack gap={1}>{renderContent()}</Stack>
      </InfiniteScroll>
    </Box>
  );
};

const ListLoader: React.FC<{ loadingOccurences: number[] }> = ({
  loadingOccurences,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="body2">Fetching more products... ✋</Typography>
      {loadingOccurences.map((val) => (
        <SkeletonCard key={val} />
      ))}
    </div>
  );
};

export const ListEndMessage: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography align="center" variant="body2">
        That's it for now... 😀
      </Typography>
    </div>
  );
};
