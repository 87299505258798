import { SearchField } from "../../Common/InputComponents/SearchField";
import SearchIcon from "@mui/icons-material/Search";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  Typography,
  Chip,
  Skeleton,
} from "@mui/material";
import { CustomPaginationActionsTable } from "../../Common/Table/CustomTableComponent";
import { debounce } from "lodash";
import { useCouponStore } from "../../../../store/main/CouponStore";
import { MultipleSelectCheckmarks } from "../../Common/InputComponents/MultipleSelectCheckmarks";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { Stack } from "@mui/system";
import { DoctorFilters } from "../../../../store/slices/Doctors/FetchDoctorsListSlice";
import { FetchState } from "../../../../domain/models/fetch-state-type";

type Props = {
  handleCallBack: Function;
};

type SelectedFilters = {
  [key: string]: boolean;
};

const filterItems = [
  {
    label: "Headquarter",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Region",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Area",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Zone",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Country",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
];

export const AddNewDoctorModal: React.FC<Props> = ({ handleCallBack }) => {
  const {
    fetchDoctorsList,
    doctorsList,
    totalDoctors,
    fetchDoctorListState,
    applyDoctorFilters,
    doctorFilters,
  } = useDoctorStore();
  const {
    getHeadquartersListState,
    headquartersList,
    applyHeadquartersFilter,
  } = useCouponStore();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const modifiedData = doctorsList.map((row) => ({
    ...row,
    id: row._id.toString(),
  }));
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    Headquarter: false,
    Region: false,
    Country: false,
    Zone: false,
    Area: false,
  });
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [filters, setFilters] = useState<DoctorFilters>({
    q: "",
    hq_code: "",
  });

  const handleSelectCallback = (data: any) => {
    setSelectedRows(data);
    handleCallBack(data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchDoctorsList(page + 1, rowsPerPage, false, doctorFilters);
    applyHeadquartersFilter({});
  }, [page, rowsPerPage]);

  const handleFilterClick = (label: string) => {
    setSelectedFilters((prevSelected) => {
      const updatedFilters = { ...prevSelected, [label]: !prevSelected[label] };

      const selectedKeys = Object.keys(updatedFilters).filter(
        (key) => updatedFilters[key] === true
      );
      applyHeadquartersFilter({
        org_id: 2,
        department_id: 4,
        types: selectedKeys,
      });
      return updatedFilters;
    });
  };

  const handleSearch = (e: any) => {
    applyDoctorFilters({ ...filters, q: e.target.value });
    setFilters({ ...filters, q: e.target.value });
  };

  const handleHQChange = (e: any) => {
    applyDoctorFilters({ ...filters, hq_code: e?.code });
    setFilters({ ...filters, hq_code: e?.code });
  };

  const Searchdebounce = debounce(handleSearch, 1000);

  return (
    <>
      <Stack
        direction={"row"}
        flexWrap="wrap"
        gap={1}
        mb={2}
        justifyContent={"flex-end"}
      >
        {filterItems.map((data, index) => (
          <Chip
            icon={data.icon}
            sx={{ width: "100px", py: 2 }}
            key={index}
            size={"small"}
            label={data.label}
            color={selectedFilters[data.label] === true ? "primary" : "default"}
            onClick={() => {
              handleFilterClick(data.label);
            }}
          />
        ))}
      </Stack>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <SearchField
          handleChange={Searchdebounce}
          placeholder="Search...."
          startAdornment={<SearchIcon />}
        />
        {getHeadquartersListState === FetchState.LOADING && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={500}
            height={53}
          />
        )}
        {getHeadquartersListState === FetchState.SUCCESS && (
          <MultipleSelectCheckmarks
            width={500}
            names={headquartersList}
            onSelectCallback={handleHQChange}
          />
        )}
      </Stack>
      {fetchDoctorListState === "LOADING" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {fetchDoctorListState === "SUCCESS" && (
        <>
          <CustomPaginationActionsTable
            data={modifiedData}
            totalCount={totalDoctors}
            selectedRows={selectedRows}
            handleSelectCallback={handleSelectCallback}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}
      {fetchDoctorListState === "ERROR" && (
        <Typography>No Data Found</Typography>
      )}
    </>
  );
};
