import { Stack, Typography } from "@mui/material";
import { HeaderText } from "../../Common/Typography/HearderText";
import CustomizedInputField from "../../Common/InputComponents/InputField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import InfoSkeleton from "../../Common/Skeleton/InfoSkeleton";

export const DoctorInformation = () => {
  const { selectedDoctor, fetchDoctorListState } = useDoctorStore();
  return (
    <>
      {fetchDoctorListState === FetchState.LOADING && <InfoSkeleton />}
      {fetchDoctorListState === FetchState.SUCCESS && (
        <>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HeaderText title={selectedDoctor.full_name} />
            <Typography color={"primary"} sx={{ display: "flex", gap: 1 }}>
              <CheckCircleOutlineIcon /> Status: Active
            </Typography>
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-around"}
            width={"100%"}
          >
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <CustomizedInputField
                placeholder={""}
                value={selectedDoctor.msl_code}
                handleChange={() => {}}
                label="MSL No"
              />
              <CustomizedInputField
                placeholder={""}
                value={selectedDoctor.mobile}
                handleChange={() => {}}
                label="Mobile No"
              />
            </Stack>
            <CustomizedInputField
              fullWidth
              placeholder={""}
              value={selectedDoctor.speciality_names}
              handleChange={() => {}}
              label="Specialities"
            />
          </Stack>
        </>
      )}
      {fetchDoctorListState === FetchState.ERROR && (
        <Typography>Something went wrong</Typography>
      )}
    </>
  );
};
