import { Box, Stack, Typography, Button } from "@mui/material";
import { TwoLayerText } from "../../Common/Typography/TwoLayerText";
import { TableComponent } from "../../Common/Table/TableComponent";
import { DataGridTools } from "../CouponComponents/DataGridTools";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ModalContainer from "../../Common/Modal/ModalContainer";
import { ViewModal } from "../../Common/Modal/ViewModal";
import { ConfirmationModal } from "../../Common/Modal/ConfirmationModal";
import { AddNewCouponModal } from "./AddNewCouponModal";
import { GridColDef } from "@mui/x-data-grid";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { BasicTable } from "../../Common/Table/BasicTable";
import { useCouponStore } from "../../../../store/main/CouponStore";

export const CouponsList = () => {
  const { addCustomers } = useCouponStore();
  const { doctorCouponsList, selectedDoctor } = useDoctorStore();
  const { deleteCustomers } = useCouponStore();
  const [selectedDeleteCoupon, setSelectedDeleteCoupon] = useState<any[]>([]);
  const [selectedAddCoupon, setSelectedAddCoupon] = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openAddCouponModal, setOpenAddCouponModal] = useState<boolean>(false);
  const [openProductsModal, setOpenProductsModal] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const couponsColumns: GridColDef[] = [
    { field: "name", headerName: "Coupon Code", flex: 1.3 },
    { field: "code", headerName: "Coupon Name", flex: 1.3 },
    { field: "start_date", headerName: "Registration Date", flex: 1 },
    { field: "discount", headerName: "Discount", flex: 0.7 },
    { field: "discount_type", headerName: "Discount_type", flex: 1 },
    { field: "status", headerName: "Status", flex: 0.7 },
    {
      field: "products",
      headerName: "products",
      flex: 1,
      renderCell: (params) => {
        const handleProductsButtonClick = () => {
          console.log("Show products for coupon:", params.row);
          setOpenProductsModal(true);
          setSelectedProducts([params.row]);
        };

        return (
          <Button onClick={handleProductsButtonClick}>
            <Typography textTransform={"none"}>products</Typography>
          </Button>
        );
      },
    },
  ];

  const handleDeleteCallback = (data: any) => {
    setSelectedDeleteCoupon(data);
  };

  const handleAddCallback = (data: any) => {
    setSelectedAddCoupon(data);
  };

  const buttons = [
    {
      label: "Map new Coupon",
      action: () => {
        setOpenAddCouponModal(true);
      },
      icon: <AddIcon />,
    },
    ...(selectedDeleteCoupon.length > 0
      ? [
          {
            label: <Typography color={"error"}>Delete</Typography>,
            icon: <DeleteIcon color="error" />,
            action: () => {
              setOpenDeleteModal(true);
            },
          },
        ]
      : []),
  ];

  return (
    <Box>
      <Stack direction={"row"} gap={4} mb={2}>
        <TwoLayerText
          containerStyle={{ borderRight: "1px solid lightgrey", padding: 2 }}
          headerText={"In current doctor"}
          subText={{
            colouredText: doctorCouponsList.length.toString(),
            text: "Coupons",
          }}
        />
      </Stack>
      <DataGridTools buttons={buttons} />
      {doctorCouponsList.length > 0 ? (
        <TableComponent
          tableData={doctorCouponsList}
          columns={couponsColumns}
          limit={5}
          handleSelectCallback={handleDeleteCallback}
        />
      ) : (
        <Typography>No Coupons associated to this doctor</Typography>
      )}
      <ConfirmationModal
        title={"Delete selected coupons?"}
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        handleAction={() => {
          selectedDeleteCoupon.map(async (coupon: any) => {
            await deleteCustomers(coupon.id, [selectedDoctor._id]);
          });
          setOpenDeleteModal(false);
        }}
        primaryButtonText={"Confirm Delete"}
        secondaryButtonText={"Cancel"}
        modalContent={`Please confirm to delete the selected coupons from the doctor #${selectedDoctor._id}`}
      />
      {openProductsModal && (
        <ViewModal
          title={selectedProducts[0]?.name}
          open={openProductsModal}
          onClose={() => {
            setOpenProductsModal(false);
          }}
          handleAction={() => {}}
          primaryButtonText={"Close"}
          modalContent={
            <BasicTable
              data={selectedProducts[0].products}
              couponInfo={selectedProducts[0]}
            />
          }
        />
      )}
      <ModalContainer
        title={"Add New Coupon"}
        open={openAddCouponModal}
        handleClose={() => {
          setOpenAddCouponModal(false);
          setSelectedAddCoupon([]);
        }}
        handleAction={() => {
          selectedAddCoupon.map(async (coupon) => {
            await addCustomers(coupon.id, [
              {
                ...selectedDoctor,
                speciality: selectedDoctor.speciality_names,
              },
            ]);
          });
          setSelectedAddCoupon([]);
          setOpenAddCouponModal(false);
        }}
        children={<AddNewCouponModal handleCallBack={handleAddCallback} />}
        primaryButtonText={selectedAddCoupon.length > 0 ? "Add Coupons" : ""}
      />
    </Box>
  );
};
