import { Box } from "@mui/material";
import { SideLayout } from "./SideLayout";
import { memo } from "react";

type Props = {
  children: React.ReactNode;
};

export const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <SideLayout />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          my: 1,
          mx: 4,
          overflow: "hidden",
          marginRight: { xs: 2, sm: 2, md: 0, lg: 0 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const Layout = memo(AppLayout);
