import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {Endpoints} from "../../../domain/Endpoints";
import { STAGIN_AUTH_URL,AUTH_TOKEN_KEY,GA_PRODUCT_SERVICE_HEADER_TOKEN_VALUE,GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_VALUE,GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_VALUE } from "../../../base";
import Swal from "sweetalert2";
import { OtpLogin } from "../../../domain/usages/auth/otp-login";
import { RemoteOtpLogin } from "../../../data/usecases/auth/remote-otp-login";
import { LoginFormFields } from "../../../domain/models/auth/loginFormFields";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/auth/local-logged-in-user";

interface LoginInterface {
  submitLogin: Function;
  loginLoading: boolean;
  isLoggedin: boolean
}

const initialState = {
  loginLoading: false,
  isLoggedin:false
};

export const useLoginSlice: StateCreator<LoginInterface> = (set, get) => {
  const axiosHttpClient = AxiosHttpClient.getInstance();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);

  return {
    ...initialState,
    submitLogin: async (data: LoginFormFields, handleNavigation: Function) => {
      const verifyOtpLogin: OtpLogin = new RemoteOtpLogin(
        `${STAGIN_AUTH_URL}${Endpoints.VERIFY_LOGIN}`,
        axiosHttpClient
      );
      set(() => ({
        loginLoading: true,
      }));
      
      let payload = {
        mobile: data.mobile,
        otp: data.otp,
      };
      let result: any = await verifyOtpLogin.login(payload);
      if (result.success) {
        loggedInUser.setToken(AUTH_TOKEN_KEY);
        loggedInUser.setProductToken(GA_PRODUCT_SERVICE_HEADER_TOKEN_VALUE);
        loggedInUser.setProductAuthToken(GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_VALUE);
        loggedInUser.setDoctorAuthToken(GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_VALUE);
        set(() => ({
          loginLoading: false,
        }));
        handleNavigation(true, `Hi, you have successfully logged in`);
        set(() => ({
            isLoggedin: true,
          }));
      } else {
        set(() => ({
          loginLoading: false,
        }));
        if (result.errors) {
          Swal.fire(result.errors.message, "", "error");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
      
    },
  };
};
