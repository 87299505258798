import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchCouponsList } from "../../../domain/models/coupons/fetch-coupons-list";
import { CouponsList } from "../../../domain/models/coupons/fetch-coupons-list";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER, AUTH_TOKEN_KEY } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteFetchCouponsList } from "../../../data/usecases/coupons/remote-fetch-coupons-list";
import { CouponFilter } from "../../../domain/models/coupons/coupon-filters";

interface FetchCouponsListSliceInterface {
  fetchCouponsList: Function;
  fetchState: FetchState;
  currentPage: number;
  lastPage: number;
  listItemsCount: number;
  couponList: FetchCouponsList["coupons"];
  selectedCoupon: CouponsList;
  onSelectedCoupon: Function;
  handleAppliedFilters: Function;
  filter: CouponFilter;
  currentCouponIndex: number
}

let initialFilterPayload = {
  q: "",
  expire_date: ""
};

const initialStates = {
  fetchState: FetchState.DEFAULT,
  couponList: [] as FetchCouponsList["coupons"],
  selectedCoupon: {} as CouponsList,
  filter:initialFilterPayload,
  currentPage: 1,
  lastPage: 1,
  listItemsCount: 0,
  currentCouponIndex:0
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchcouponListSlice: StateCreator<
  FetchCouponsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchCouponsList: async (page: number,length:number,scrolled: boolean,filter:CouponFilter) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteFetchCouponDetails = new RemoteFetchCouponsList(
      `${BASE_API_URL}${Endpoints.FETCH_COUPONS_LIST}`,
      axiosHttpClient
    );
    
    try {
      if (!scrolled) {
        set(() => ({ fetchState: FetchState.LOADING }));
      }
      let result = await remoteFetchCouponDetails.fetch(length,page,filter);
      if (result) {
        set(() => ({
          currentPage: page,
          lastPage: Math.ceil(result?.total/length),
          listItemsCount: result?.total,
        }));
        
        if (scrolled) {
          set(() => ({ couponList: [...get().couponList, ...result.coupons] }));
        } else {
          set(() => ({ couponList: result.coupons }));
          
          set(() => ({ selectedCoupon: { ...get().couponList[0] }, currentCouponIndex:0 }));
          useCouponStore.getState().fetchCouponCustomerList(get().couponList[0].id)
          
        }
        set(() => ({ fetchState: FetchState.SUCCESS }));
      }
    } catch (err) {
     set(() => ({ fetchState: FetchState.ERROR }));
    }
  },
  onSelectedCoupon: (index: number) => {
    set(() => ({ selectedCoupon: { ...get().couponList[index] }, currentCouponIndex:index }));
    useCouponStore.getState().fetchCouponCustomerList(get().couponList[index].id)
  },

  handleAppliedFilters: async(appliedFilters:any)=>{
    if(appliedFilters["Expires First"]==="default"){
      appliedFilters.expire_date=""
    }else{
      appliedFilters.expire_date=appliedFilters["Expires First"]
    }
    appliedFilters["InActive"]==="asc" ? appliedFilters.status="inactive" : delete appliedFilters.status
    let filter
    if(appliedFilters["InActive"]==="asc"){
      filter={q:appliedFilters.q,expire_date:appliedFilters.expire_date,status:"inactive"}
      set(()=>({filter:{q:appliedFilters.q,expire_date:appliedFilters.expire_date,status:"inactive"}}))
    }else{
      filter={q:appliedFilters.q,expire_date:appliedFilters.expire_date}
      set(()=>({filter:{q:appliedFilters.q,expire_date:appliedFilters.expire_date}}))
    }
    useCouponStore.getState().fetchCouponsList(1,10,false,filter)
  }
});
