import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import { useRef } from "react";
import { useCouponStore } from "../../../../store/main/CouponStore";

type Props = {
  data: any;
  totalCount: number;
  handleSelectCallback: Function;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  page: number;
  rowsPerPage: number;
  oldSelectedRows: any;
  discount_type: string;
  discount: string;
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export const CustomProductsTable: React.FC<Props> = ({
  data,
  totalCount,
  handleSelectCallback,
  handleChangePage,
  handleChangeRowsPerPage,
  oldSelectedRows,
  page,
  rowsPerPage,
  discount,
  discount_type,
}) => {
  const { selectedCoupon } = useCouponStore();
  const selectedRowsRef = useRef<any[]>(oldSelectedRows);
  const selectedRows = selectedRowsRef.current;

  const handleCheckboxChange = (product: any, uom: any) => {
    const index = selectedRows.findIndex((r: any) => r.id === product.id);
    const u_i = selectedRows[index]?.prices.findIndex(
      (r: any) => r.uom_id === uom.uom_id
    );
    if (index !== -1) {
      if (u_i !== -1) {
        if (selectedRows[index].prices.length > 1) {
          selectedRows[index].prices.splice(u_i, 1);
        } else {
          selectedRows.splice(index, 1);
        }
      } else {
        selectedRows.splice(0, 1);
        selectedRows[index].prices.push({
          ...uom,
          type: discount_type,
          discount: discount,
        });
      }
    } else {
      selectedRows.splice(0, 1);
      selectedRows.push({
        id: product.id,
        product_id: product.product_id,
        name: product.name,
        description: product.description,
        variant_id: product.variant_id,
        prices: [
          {
            ...uom,
            type: discount_type,
            discount: discount,
          },
        ],
      });
    }
    selectedRowsRef.current = [...selectedRows];
    handleSelectCallback(selectedRows);
  };

  const handleSelectAllCheckboxChange = () => {
    if (selectedRows.length > 0) {
      handleSelectCallback([]);
      selectedRowsRef.current = [];
    }
  };

  const isSelected = (row: any) =>
    selectedRows.some((r: any) =>
      r.prices.some((price: any) => price.uom_id === row.uom_id)
    );

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - data.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="center" padding="checkbox">
              <Checkbox
                indeterminate={
                  Object.values(selectedRows).length > 0 &&
                  Object.values(selectedRows).length < data.length
                }
                checked={Object.values(selectedRows).length === data.length}
                onChange={handleSelectAllCheckboxChange}
              />
            </TableCell>
            <TableCell align="center">UOM</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((product: any) => (
            <TableRow key={product.id}>
              <TableCell>{product.name}</TableCell>
              <TableCell colSpan={5} sx={{ width: "400px" }}>
                <TableContainer component={Paper}>
                  <Table
                    size="small"
                    aria-label="UOMs for product {product.code}"
                  >
                    <TableBody>
                      {product.prices.map((uom: any) => (
                        <TableRow key={uom.uom_id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected(uom)}
                              onChange={() =>
                                handleCheckboxChange(product, uom)
                              }
                              disabled={
                                discount_type === "flat" &&
                                parseInt(discount) > parseInt(uom.value)
                              }
                            />
                          </TableCell>
                          <TableCell align="center">{uom.uom_units}</TableCell>
                          <TableCell align="left">{uom.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
