import { StateCreator } from "zustand";
import { Product } from "../../../domain/usages/common/product";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteAddProducts } from "../../../data/usecases/coupons/remote-add-products";
import Swal from 'sweetalert2'

interface AddProductsInterface {
    addProducts: Function;
    loading: boolean;
}

const initialStates = {
    loading: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();


export const useAddProductsSlice: StateCreator<
AddProductsInterface
> = (set, get) => ({
  ...initialStates,

  addProducts: async (id:number,products:Product[] ) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteAddProducts = new RemoteAddProducts(
      `${BASE_API_URL}${Endpoints.ADD_PRODUCT_COUPON}`,
      axiosHttpClient
    );
    const payload={
        products:products
    }
    set(() => ({
        loading: true,
      }));

    try {
      let result = await remoteAddProducts.add(id.toString(),payload);
      if (result.success) {
        set(() => ({ loading: false }));
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useCouponStore.getState().fetchCouponsList(1,10, false, useCouponStore.getState().filter);
      }else if(result.errors){
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    } catch (err) {
        set(() => ({ loading: false }));
  }
}
  
});
