import { Typography } from "@mui/material";

type Props={
    title:string
};

export const HeaderText:React.FC<Props> =({title})=>{
    return(
        <Typography variant="h5" fontWeight={700}>{title}</Typography>
    );
};