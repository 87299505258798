import { TextField } from "@mui/material";

type Props = {
  startAdornment?: any;
  value?: string;
  placeholder?: string;
  sx?: any;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const SearchField: React.FC<Props> = ({
  startAdornment,
  value,
  placeholder,
  sx,
  handleChange,
}) => {
  return (
    <TextField
      sx={sx}
      InputProps={{
        style: {
          minWidth: 180,
          maxWidth: "80%",
          height: "36px",
          borderRadius: "4px",
          fontSize: "0.9375rem",
          boxShadow: "none",
        },
        startAdornment: startAdornment,
      }}
      value={value}
      placeholder={placeholder}
      onChange={(e) => handleChange(e)}
    />
  );
};
