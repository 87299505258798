import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteDeleteProducts } from "../../../data/usecases/coupons/remote-delete-products";
import Swal from 'sweetalert2'

interface DeleteProductsInterface {
    deleteProducts: Function;
    deleteState: FetchState;
    loading: boolean;
}

const initialStates = {
    deleteState: FetchState.DEFAULT,
    loading: false,
};

type Product_ids={
  product_uuid:string,
  uom_uuid:string
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();


export const useDeleteProductsSlice: StateCreator<
DeleteProductsInterface
> = (set, get) => ({
  ...initialStates,

  deleteProducts: async (id: number, product_ids: Product_ids[]) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({ })
    const remoteFetchDoctorDetails = new RemoteDeleteProducts(
      `${BASE_API_URL}${Endpoints.REMOVE_PRODUCT_COUPON}`,
      axiosHttpClient
    );
    set(() => ({
        loading: true,
      }));

    const payload = {
      product_ids: product_ids
    };
    try {
      let result = await remoteFetchDoctorDetails.remove(id.toString(), payload);
      if (result.success) {
        set(() => ({ loading: false }));
        useCouponStore.getState().fetchCouponsList(1,10,false,useCouponStore.getState().filter)
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      } else{
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
     
    } catch (err) {
        set(() => ({ loading: false }));
  }
}
  
});
