import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Product } from "../../../domain/usages/common/product";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteCreateCoupon } from "../../../data/usecases/coupons/remote-create-coupon";
import Swal from 'sweetalert2'

interface CreateCouponInterface {
    createCoupon: Function;
    fetchState: FetchState;
    loading: boolean;
}

const initialStates = {
    fetchState: FetchState.DEFAULT,
    loading: false,
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();


export const useCreateCouponSlice: StateCreator<
CreateCouponInterface
> = (set, get) => ({
  ...initialStates,

  createCoupon: async (
    code: String,
    name:string,
    description: String,
    app_code: String,
    min_qty: string,
    discount_type: String,
    discount: String,
    start_date: String,
    expiry_date: String,
    usage_limit: String,
    products: Product[]
    ) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteCreateCoupon = new RemoteCreateCoupon(
      `${BASE_API_URL}${Endpoints.CREATE_COUPON}`,
      axiosHttpClient
    );
    set(() => ({
        loading: true,
      }));

    const payload = {
        code: code,
        name:name,
        description: description,
        app_code: app_code,
        min_qty:min_qty,
        discount_type: discount_type,
        discount: discount,
        start_date: start_date,
        expiry_date: expiry_date,
        usage_limit: usage_limit,
        products: products,
    };
    try {
      let result = await remoteCreateCoupon.create(payload);
      if (result.success) {
        set(() => ({ loading: false }));
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useCouponStore.getState().fetchCouponsList(1,10,false,useCouponStore.getState().filter);
      }else if(result.errors){
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
      
    } catch (err) {
        set(() => ({ loading: false }));
  }
}
  
});
