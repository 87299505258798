import { Box, Button, Slide, IconButton, Typography } from "@mui/material";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type myProps = {
  title: string;
  open: boolean;
  handleClose: Function;
  handleCallBack?: Function;
  handleAction: Function;
  children?: React.ReactNode;
  primaryButtonText?: string;
  error_message?: string;
};

const ModalContainer: React.FC<myProps> = ({
  title,
  open,
  handleClose,
  handleAction,
  children,
  primaryButtonText,
  error_message,
}) => {
  return (
    <>
      <Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          maxWidth={"md"}
          keepMounted
          onClose={() => handleClose()}
        >
          <DialogTitle>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              minWidth:
                title === "Add New Product"
                  ? "600px !important"
                  : "850px !important",
            }}
          >
            {children}
          </DialogContent>
          <DialogContent>
            {error_message && (
              <Typography sx={{ color: "red" }}>{error_message}</Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ m: 2, justifyContent: "start" }}>
            {primaryButtonText && (
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                onClick={() => handleAction()}
                type={"submit"}
              >
                {primaryButtonText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ModalContainer;
