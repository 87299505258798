import { Box, Stack, Typography } from "@mui/material";

type Props = {
  headerText: string;
  subText: {
    colouredText: string;
    text: string;
  };
  containerStyle?: any;
};

export const TwoLayerText: React.FC<Props> = ({
  headerText,
  subText,
  containerStyle,
}) => {
  return (
    <Stack sx={containerStyle}>
      <Typography color={"grey"} variant="caption">
        {headerText}
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography fontWeight={600} color={"primary"} variant="h5">
          {subText.colouredText}
        </Typography>
        <Typography variant="body1">{subText.text}</Typography>
      </Stack>
    </Stack>
  );
};
