import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchDoctorsList } from "../../../domain/models/doctors/fetch-doctors-list";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { STAGING_DOC_URL, GA_AUTH_HEADER, GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteFetchDoctorsList } from "../../../data/usecases/doctors/remote-fetch-doctors-list";
import { useDoctorStore } from "../../main/DoctorStore";
import { gte } from "lodash";

export type DoctorFilters={
  q?:string
  hq_code?:string
}

interface FetchDoctorsListSliceInterface {
  fetchDoctorsList: Function;
  fetchDoctorListState: FetchState;
  doctorsList: FetchDoctorsList[];
  currentDoctorPage: number;
  lastDoctorPage: number;
  totalDoctors: number;
  selectedDoctor: FetchDoctorsList;
  onSelectedDoctor: Function;
  applyDoctorFilters: Function;
  doctorFilters: DoctorFilters;
  rowsPerPage:number;
}

let initialFilterPayload = {
  q: "",
  hq_code: ""
};

const initialStates = {
  fetchDoctorListState: FetchState.DEFAULT,
  currentDoctorPage: 1,
  lastDoctorPage: 2,
  totalDoctors:0,
  doctorsList: [],
  selectedDoctor: {} as FetchDoctorsList,
  doctorFilters: initialFilterPayload,
  rowsPerPage:10,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDoctorListSlice: StateCreator<
  FetchDoctorsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchDoctorsList: async (page:number,length:number, scrolled: boolean, filter:DoctorFilters) => {
    const doctorAuthToken = storage.get(GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [GA_AUTH_HEADER]: atob(doctorAuthToken)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteFetchDoctorDetails = new RemoteFetchDoctorsList(
      `${STAGING_DOC_URL}${Endpoints.FETCH_DOCTORS_LIST}`,
      axiosHttpClient
    );
    try {
      if (!scrolled) {
        set(() => ({ fetchDoctorListState: FetchState.LOADING, rowsPerPage:length }));
      }

      let result = await remoteFetchDoctorDetails.fetch(length,page,filter);
      if (result) {
        set(() => ({ 
          currentDoctorPage: page,
          lastDoctorPage: Math.ceil(result?.total/length),
          totalDoctors: result.total  
        }));
        
        if (scrolled) {
          set(() => ({ doctorsList: [...get().doctorsList, ...result.doctors] }));
        } else {
          set(() => ({ doctorsList: result.doctors }));
          set(() => ({ selectedDoctor: { ...get().doctorsList[0] } }));
          useDoctorStore.getState().fetchDoctorCouponsList(get().doctorsList[0]._id)
        }
        if(result.total>0){
        set(() => ({ fetchDoctorListState: FetchState.SUCCESS }));
        }else{
          set(() => ({ fetchDoctorListState: FetchState.ERROR }));
        }
      }
    } catch (err) {
      set(() => ({ fetchDoctorListState: FetchState.ERROR }));
    }
  },
  onSelectedDoctor: (index: number) => {
    set(() => ({ selectedDoctor: { ...get().doctorsList[index] } }));
    useDoctorStore.getState().fetchDoctorCouponsList(get().doctorsList[index]._id)
  },

  applyDoctorFilters:async(appliedFilters:any,length?:number)=>{
    set(()=>({doctorFilters:appliedFilters}))
    useDoctorStore.getState().fetchDoctorsList(1,length?length:get().rowsPerPage,false,appliedFilters)
  }
  
});
