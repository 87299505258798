import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { OrganizationsList } from "../../../domain/models/common/get-organizations-list";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { GA_AUTH_HEADER,STAGIN_AUTH_URL,HEADQUARTED_API_KEY } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteGetOrganizationsList } from "../../../data/usecases/common/remote-get-organizations-list";

interface GetOrganizationsListSliceInterface {
  getOrganizationsList: Function;
  organizationsList: OrganizationsList["organizations"]
  fetchOrganizationsState: FetchState;
}


const initialStates = {
  fetchOrganizationsState: FetchState.DEFAULT,
  organizationsList: [] as OrganizationsList["organizations"]
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useGetOrganizationsListSlice: StateCreator<
  GetOrganizationsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  getOrganizationsList: async () => {
    axiosHttpClient.setAuthHeaders({ [GA_AUTH_HEADER]: HEADQUARTED_API_KEY})
    axiosHttpClient.setAuthHeaders1({})
    const remoteGetOrganizationsList = new RemoteGetOrganizationsList(
      `${STAGIN_AUTH_URL}${Endpoints.GET_ORGANIZATIONS_LIST}`,
      axiosHttpClient
    );
    
    try {
        set(() => ({ fetchOrganizationsState: FetchState.LOADING }));
      let result = await remoteGetOrganizationsList.get();
      if (result.success) {
        set(()=>({fetchOrganizationsState:FetchState.SUCCESS}))
        
        set(()=>({organizationsList: result.organizations}))
      }
    } catch (err) {
     set(() => ({ fetchOrganizationsState: FetchState.ERROR }));
    }
  },
});
