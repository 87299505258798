import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { UpdateRequestType } from "../../../../domain/models/discount-approvals/update-request-type";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

type Props = {
  handleUpdateStatus: Function;
  openModal: boolean;
  handleCloseModal: Function;
  data: UpdateRequestType;
  handleUpdateStatusChange: Function;
};

const DiscountApprovalsActionModal: React.FC<Props> = ({
  handleUpdateStatus,
  openModal = false,
  handleCloseModal,
  data,
  handleUpdateStatusChange,
}) => {
  const [remarks, setRemarks] = React.useState<string>("");
  const [editedDiscount, setEditedDiscount] = React.useState<number | null>(
    null
  );
  React.useEffect(() => {
    if (openModal) {
      setEditedDiscount(parseFloat(data.selectedDiscount));
    }
  }, [openModal]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => handleCloseModal()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              marginBottom={2}
            >
              Before submitting, please confirm:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Product Name:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {data.productName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={660}>
                        MOQ:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2"> {data.MOQ}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Discount As Per Slab:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {" "}
                        {data.discountAsPerSlab ? data.discountAsPerSlab : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Requested Discount:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {data.action == "approve" && (
                        <Typography variant="subtitle2">
                          {data.selectedDiscount}
                        </Typography>
                      )}

                      {data.action == "edit" && (
                        <TextField
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "any",
                          }}
                          onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          size="small"
                          value={editedDiscount}
                          placeholder="Enter Discount Value..."
                          onChange={(e) => {
                            setEditedDiscount(parseFloat(e.target.value));
                            handleUpdateStatusChange(
                              e.target.value,
                              "editDiscount"
                            );
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Remarks:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Enter Remarks..."
                        onChange={(e) => {
                          setRemarks(e.target.value);
                          handleUpdateStatusChange(e.target.value, "remarks");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"flex-end"}
              marginTop={5}
            >
              <Button
                color="error"
                onClick={() => handleCloseModal(false, data)}
              >
                <Typography textTransform={"none"}>Cancel</Typography>
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  remarks == "" ||
                  (editedDiscount == null && data.action == "edit")
                }
                onClick={() => handleUpdateStatus()}
              >
                <Typography textTransform={"none"}>Submit</Typography>
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
export default DiscountApprovalsActionModal;
