import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

type TabsContent = {
  label: string;
  content: React.ReactNode;
};

type Props = {
  tabs: TabsContent[];
};

export const TabComponent: React.FC<Props> = ({ tabs }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        style={{ padding: 3, width: "100% !important", marginTop: "20px" }}
      >
        {value === index && (
          <div
            style={{ padding: 3, width: "100% !important", marginInline: 20 }}
          >
            {children}
          </div>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ bgcolor: "#f9f9f9" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        sx={{
          minHeight: "auto !important",
          borderRadius: "45px !important",
          border: "20px !important",
          background: "#E9F7FF !important",
          color: "#000",
          justifyContent: "space-between",
          width: "60%",
          ".MuiTabs-indicator": {
            height: "100% !important",
            zIndex: 0,
            background: `primary !important`,
            borderRadius: "45px",
            color: "#fff",
          },
          ".MuiTabs-scrollButtons": {
            width: "20px",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            sx={{
              zIndex: 1,
              height: "30px",
              width: "50%",
              fontSize: "1rem !important",
              fontWeight: `500 !important`,
              padding: "2px 2px!important",
              color: `#000 !important`,
              textTransform: "none",
              px: "50px !important",

              "&.Mui-selected": {
                color: `#fff !important`,
                fontWeight: `${500} !important`,
                px: "50px !important",
              },
            }}
            key={index}
            label={<Typography variant="subtitle2">{tab.label}</Typography>}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};
