export class Constants {
  
    static YEAR_MONTH_DATE_FORMAT = "YYYY-MM-DD";
    static DATE_MONTH_YEAR_FORMAT = "DD/MM/YYYY";
    static MOMENT_MONTH_DATE_YEAR= "ll";
  
    //server error
  
    static SERVER_ERROR = "Server error. Please try after sometime.";
    static OTP_ERROR = "OTP entered is wrong. Please enter correct OTP.";
    static AUTHORIZATION_ERROR = "You are not an authorized user.";
  
    //Field Check
    static FIELD_REQUIRED = "Field Required";
    static MOBILE_REGEX = /^[6-9]{1}[0-9]{9}$/i;
    static MOBILE_CHECK = "Mobile number should be 10 digits";
  }
  