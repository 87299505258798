import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER } from "../../../base";
import { useCouponStore } from "../../main/CouponStore";
import { useDoctorStore } from "../../main/DoctorStore";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteAddCustomers } from "../../../data/usecases/coupons/remote-add-customers";
import Swal from 'sweetalert2'

interface AddCustomersInterface {
    addCustomers: Function;
    loadingCustomerAddition: boolean;
}

const initialStates = {
    loadingCustomerAddition: false,
};

  type Customer = {
    _id: string;
    full_name: string;
    speciality: string;
  };

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useAddCustomersSlice: StateCreator<
AddCustomersInterface
> = (set, get) => ({
  ...initialStates,

  addCustomers: async (id:number,customers:Customer[] ) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteAddCustomers = new RemoteAddCustomers(
      `${BASE_API_URL}${Endpoints.ADD_COUPON_CUSTOMER}`,
      axiosHttpClient
    );
    console.log(customers)
    const modified_data = customers.map((customer) => ({
      customer_uuid: customer._id,
      name: customer.full_name,
      speciality: customer.speciality?customer.speciality:""
    }));
    const payload={
        customers:modified_data
    }
    set(() => ({
        loadingCustomerAddition: true,
      }));

    try {
      let result = await remoteAddCustomers.add(id.toString(),payload);
      if (result.success) {
        set(() => ({ loadingCustomerAddition: false }));
        useCouponStore.getState().fetchCouponCustomerList(id)
        useDoctorStore.getState().fetchDoctorCouponsList(useDoctorStore.getState().selectedDoctor._id);
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }else if(result.errors){
        Swal.fire({
          icon: "error",
          title: result.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    } catch (err) {
        set(() => ({ loadingCustomerAddition: false }));
  }
}
  
});
