import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { STAGIN_PRODUCT_URL, GA_PRODUCT_HEADER, GA_AUTH_HEADER,GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY,GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteFetchProductsList } from "../../../data/usecases/coupons/remote-fetch-products-list";
import { useCouponStore } from "../../main/CouponStore";
import { Product } from "../../../domain/models/coupons/fetch-products-list";

export type ProductFilters={
  q?:string
  product_group_ids?:string[]
}

type ModifiedProduct = {
  id:string,
  variant_id: string,
  product_id: string,
  name: string,
  description: string,
  prices:{
    uom_id:string,
    uom_units:string,
    value:string,
  }[]
}

interface FetchProductsListSliceInterface {
  FetchProductsList: Function;
  totalProducts: number;
  fetchProductListState: FetchState;
  productsList: ModifiedProduct[];
  applyProductFilters: Function;
  productFilters: ProductFilters
}

let initialFilterPayload = {
  q: "",
  org_code:"GOAPPTIV",
  product_group_ids:[],
};

const initialStates = {
  fetchProductListState: FetchState.DEFAULT,
  productsList: [],
  totalProducts: 0,
  productFilters: initialFilterPayload
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

function convertData(apiResponse: Product[]): ModifiedProduct[] {
  return apiResponse.map((productData:Product) => ({
    id:productData.product.product,
    variant_id: productData._id,
    product_id: productData.product.product,
    name: productData.name,
    description: "",
    prices: productData.uoms.map(uom => ({
      uom_id: uom._id,
      uom_units: String(uom.units),
      value: String(uom.latestPrice.priceToRetailer.price)
    }))
  }));
}

export const useFetchProductsListSlice: StateCreator<
  FetchProductsListSliceInterface
> = (set, get) => ({
  ...initialStates,
  FetchProductsList: async (length:number, page:number ,filter:ProductFilters) => {
    const authToken = storage.get(GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY);
    const productToken = storage.get(GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [GA_AUTH_HEADER]: atob(authToken)})
    axiosHttpClient.setAuthHeaders1({ [GA_PRODUCT_HEADER]: atob(productToken)})
    const remoteFetchProductDetails = new RemoteFetchProductsList(
      `${STAGIN_PRODUCT_URL}${Endpoints.FETCH_PRODUCTS_LIST}`,
      axiosHttpClient
    );
    
    const payload={
      // product_group_idss:["65cf26313e6e7f5a9d5b18d5"],
      div_code: "GA_WELLNESS",
      ...filter
    }

    try {
      set(() => ({ fetchProductListState: FetchState.LOADING, productFilters:filter }));
      let result = await remoteFetchProductDetails.fetch(length.toString(),page.toString(), payload);
      if (result.success) {
        set(() => ({ totalProducts: result.data.total_product_count }));
        if(result.data.total_product_count>0){
          const modifiedData=convertData(result.data.products)
          set(() => ({ productsList: modifiedData }));
          set(() => ({ fetchProductListState: FetchState.SUCCESS }));
        }else{
          set(() => ({ fetchProductListState: FetchState.ERROR }));
        }
      }else{
        set(() => ({ fetchProductListState: FetchState.ERROR }));
      }
    } catch (err) {
      set(() => ({ fetchProductListState: FetchState.ERROR }));
    }
  },

  applyProductFilters:async(length:number,appliedFilters:any)=>{
    set(()=>({productFilters:appliedFilters}))
    useCouponStore.getState().FetchProductsList(length,1,appliedFilters)
  }
});
