import { SearchField } from "../../Common/InputComponents/SearchField";
import { Box, CircularProgress, Typography, Stack, Chip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { useCouponStore } from "../../../../store/main/CouponStore";
import { useState, useEffect } from "react";
import { debounce } from "lodash";
import { CustomCouponTable } from "../../Common/Table/CustomCouponTable";
import { BasicTable } from "../../Common/Table/BasicTable";
import { ViewModal } from "../../Common/Modal/ViewModal";

type Props = {
  handleCallBack: Function;
};

const filterItems = [
  {
    label: "Expires First",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
];

type SortingOrder = "asc" | "default";

export const AddNewCouponModal: React.FC<Props> = ({ handleCallBack }) => {
  const {
    fetchCouponsList,
    couponList,
    listItemsCount,
    fetchState,
    handleAppliedFilters,
    filter,
  } = useCouponStore();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [openProductsModal, setOpenProductsModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any[]>([]);
  const [value, setValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, SortingOrder>
  >({
    "Expires First": "default",
  });

  const handleSelectCallback = (data: any) => {
    setSelectedRows(data);
    handleCallBack(data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchCouponsList(page + 1, rowsPerPage, false, filter);
  }, [page, rowsPerPage]);

  const handleProductsButtonClick = (row: any) => {
    console.log("Show products for coupon:", row);
    setOpenProductsModal(true);
    setSelectedProduct([row]);
  };

  const handleFilterClick = (label: string) => {
    setSelectedFilters((prevSelected) => {
      const currentOrder = prevSelected[label] || "default";
      let newOrder;
      newOrder = {
        default: "asc",
        asc: "default",
      }[currentOrder] as SortingOrder;
      handleAppliedFilters({ ...prevSelected, [label]: newOrder, q: value });
      return { ...prevSelected, [label]: newOrder };
    });
  };

  const handleSearch = (e: any) => {
    setValue(e.target.value);
    handleAppliedFilters({ ...selectedFilters, q: e.target.value });
  };

  const Searchdebounce = debounce(handleSearch, 1000);

  return (
    <>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <SearchField
          handleChange={Searchdebounce}
          placeholder="Search...."
          startAdornment={<SearchIcon />}
          sx={{ mb: 2 }}
        />
        {filterItems.map((data, index) => (
          <Chip
            icon={data.icon}
            sx={{ width: "130px", py: 2 }}
            key={index}
            size={"small"}
            label={data.label}
            color={
              selectedFilters[data.label] !== ("default" || undefined)
                ? "primary"
                : "default"
            }
            onClick={() => {
              handleFilterClick(data.label);
            }}
          />
        ))}
      </Stack>
      {fetchState === "LOADING" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {fetchState === "SUCCESS" && (
        <>
          <CustomCouponTable
            data={couponList}
            totalCount={listItemsCount}
            selectedRows={selectedRows}
            handleSelectCallback={handleSelectCallback}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            handleOpenModal={handleProductsButtonClick}
          />
        </>
      )}
      {fetchState === "ERROR" && <Typography>No Data Found</Typography>}

      {openProductsModal && (
        <ViewModal
          title={selectedProduct[0]?.name}
          open={openProductsModal}
          onClose={() => {
            setOpenProductsModal(false);
          }}
          handleAction={() => {}}
          primaryButtonText={"Close"}
          modalContent={
            <BasicTable
              data={selectedProduct[0].products}
              couponInfo={selectedProduct[0]}
            />
          }
        />
      )}
    </>
  );
};
