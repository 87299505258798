import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { STAGIN_AUTH_URL, GA_AUTH_HEADER } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteGetHeadquartersList } from "../../../data/usecases/common/remote-get-headquarters-list";
import { Headquarters } from "../../../domain/models/common/get-headquater-list";
import { useCouponStore } from "../../main/CouponStore";

export type HeadquartersFilter={
  q?:string
  ord_id?:string,
  department_id?:string,
  types?:string[]
}

interface GetHeadquartersListSliceInterface {
  getHeadquartersList: Function;
  getHeadquartersListState: FetchState;
  headquartersList: Headquarters["headquaters"];
  applyHeadquartersFilter: Function;
  headquartersFilter: HeadquartersFilter
}

let initialFilterPayload = {
  q: "",
  org_code:"",
  department_code:"",
  types:[]
};

const initialStates = {
  getHeadquartersListState: FetchState.DEFAULT,
  headquartersList: [],
  headquartersFilter: initialFilterPayload
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useGetHeadquartersListSlice: StateCreator<
  GetHeadquartersListSliceInterface
> = (set, get) => ({
  ...initialStates,
  getHeadquartersList: async (filter:HeadquartersFilter) => {
    axiosHttpClient.setAuthHeaders({ [GA_AUTH_HEADER]: "eyJhbGciOiJNRDUiLCJ0eXBlIjoiSldUIn0=.eyJ1c2VyX2lkIjo0MTM5LCJvcmdfaWQiOjIsIm9yZ19jb2RlIjoiQ0lQUkVTIiwiZGVwdF9jb2RlIjoiQ0lQUkVTQ09QRCIsImRlcGFydG1lbnRfaWQiOjcsInJvbGUiOiJITyBBZG1pbiIsInJvbGVfaWQiOjEsImZ1bGxfbmFtZSI6IlJlc3BpIEFkbWluIn0=.ab652c3a4a9bd2eff527fdb4e3a9a6ea"})
    axiosHttpClient.setAuthHeaders1({})
    const remoteGetHeadquartersList = new RemoteGetHeadquartersList(
      `${STAGIN_AUTH_URL}${Endpoints.GET_HEADQUARTERS_LIST}`,
      axiosHttpClient
    );
    
    try {
      set(() => ({ getHeadquartersListState: FetchState.LOADING }));
      let result = await remoteGetHeadquartersList.get(filter);
      if (result.success) {
        set(() => ({ headquartersList: result.headquarters }));
        if(result.headquarters.length>0){
          set(() => ({ getHeadquartersListState: FetchState.SUCCESS }));
        }else{
          set(() => ({ getHeadquartersListState: FetchState.ERROR }));
        }
      }else{
        set(() => ({ getHeadquartersListState: FetchState.ERROR }));
      }
    } catch (err) {
      set(() => ({ getHeadquartersListState: FetchState.ERROR }));
    }
  },

  applyHeadquartersFilter:async(appliedFilters:any)=>{
    set(()=>({headquartersFilter:appliedFilters}))
    useCouponStore.getState().getHeadquartersList(appliedFilters)
  }
});
