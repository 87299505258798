import { SearchField } from "../../Common/InputComponents/SearchField";
import SearchIcon from "@mui/icons-material/Search";
import { useCouponStore } from "../../../../store/main/CouponStore";
import { CircularProgress, Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomProductsTable } from "../../Common/Table/TestingProductTable";
import { ProductFilters } from "../../../../store/slices/Coupons/FetchProductsListSlice";
import { debounce } from "lodash";

type Props = {
  handleCallBack: Function;
  discount_type: string;
  discount: string;
  organization_code?: string;
};

export const AddNewProductModal: React.FC<Props> = ({
  handleCallBack,
  discount,
  discount_type,
  organization_code,
}) => {
  const {
    FetchProductsList,
    productsList,
    totalProducts,
    fetchProductListState,
    applyProductFilters,
    selectedCoupon,
  } = useCouponStore();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState<ProductFilters>({
    q: "",
    product_group_ids: [],
  });
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleSelectCallback = (data: any) => {
    setSelectedRows(data);
    const modifiedData = data.map((product: any) => {
      let NewData: any = [];
      for (let price of product.prices) {
        let price_obj = {
          id: price.uom_id,
          product_uuid: product.id.toString(),
          variant_uuid: product.variant_id.toString(),
          uom_uuid: price.uom_id.toString(),
          price: price.value.toString(),
          name: product.name,
          description: product.description,
          discount_type: price.type,
          discount: parseFloat(price.discount),
        };
        NewData.push(price_obj);
      }
      return NewData;
    });
    handleCallBack(modifiedData.flat());
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (selectedCoupon.id) {
      FetchProductsList(rowsPerPage, page + 1, {
        ...useCouponStore.getState().productFilters,
      });
    } else {
      FetchProductsList(rowsPerPage, page + 1, {
        ...useCouponStore.getState().productFilters,
      });
    }
  }, [page, rowsPerPage]);

  const handleSearch = (e: any) => {
    applyProductFilters(rowsPerPage, {
      ...filters,
      q: e.target.value,
    });
    setFilters({
      ...filters,
      q: e.target.value,
    });
  };

  const handleProductGroupIdSearch = (e: any) => {
    applyProductFilters(rowsPerPage, {
      ...filters,
      product_group_ids: [e.target.value],
    });
    setFilters({
      ...filters,
      product_group_ids: [e.target.value],
    });
  };

  const Searchdebounce = debounce(handleSearch, 1000); //for delaying search for better ux
  const ProductIDSearchdebounce = debounce(handleProductGroupIdSearch, 1000); //for delaying search for better ux

  return (
    <>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SearchField
          handleChange={Searchdebounce}
          placeholder="Search...."
          startAdornment={<SearchIcon />}
          sx={{ mb: 2, maxWidth: "50%" }}
        />
        <SearchField
          handleChange={ProductIDSearchdebounce}
          placeholder="Product group id"
          startAdornment={<SearchIcon />}
          sx={{ mb: 2, maxWidth: "60%" }}
        />
      </Stack>

      {fetchProductListState === "LOADING" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {fetchProductListState === "SUCCESS" && (
        <>
          <CustomProductsTable
            data={productsList}
            totalCount={totalProducts}
            handleSelectCallback={handleSelectCallback}
            oldSelectedRows={selectedRows}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            discount={discount}
            discount_type={discount_type}
          />
        </>
      )}
      {fetchProductListState === "ERROR" && (
        <Typography>No Data Found</Typography>
      )}
    </>
  );
};
