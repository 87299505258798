import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchCouponCustomerList } from "../../../domain/models/coupons/fetch-coupon-customers";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BASE_API_URL, AUTH_HEADER, AUTH_TOKEN_KEY } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { RemoteFetchCouponCustomersList } from "../../../data/usecases/coupons/remote-fetch-coupon-customers-list";

interface FetchCouponCustomerListSliceInterface {
  fetchCouponCustomerList: Function;
  fetchCustomerState: FetchState;
  couponCustomersList: FetchCouponCustomerList[];
}

const initialStates = {
  fetchCustomerState: FetchState.DEFAULT,
  couponCustomersList: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCouponCustomersListSlice: StateCreator<
  FetchCouponCustomerListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchCouponCustomerList: async (id:number) => {
    const token = storage.get("tokenKey");
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token)})
    axiosHttpClient.setAuthHeaders1({})
    const remoteFetchCouponCustomersDetails = new RemoteFetchCouponCustomersList(
      `${BASE_API_URL}${Endpoints.FETCH_COUPON_CUSTOMERS}`,
      axiosHttpClient
    );
      if(id){
        set(()=>({fetchCustomerState: FetchState.LOADING}))
        let result = await remoteFetchCouponCustomersDetails.fetch(id.toString());
        if (result.success) {
            set(() => ({ couponCustomersList: result.customers, fetchCustomerState: FetchState.SUCCESS }));
        }else{
          set(()=>({fetchCustomerState: FetchState.ERROR}))
        }
      }        
  },
});
