import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Layout } from "../components/LayoutComponents/Layout";
import { CouponFilters } from "../components/UiComponents/CouponComponents/CouponFilters";
import { CouponListing } from "../components/UiComponents/CouponComponents/CouponListing";
import { TabComponent } from "../components/Common/TabComponent/TabComponent";
import { CouponInformation } from "../components/UiComponents/CouponComponents/CouponInformation";
import { DoctorList } from "../components/UiComponents/CouponComponents/DoctorList";
import { useState, useEffect } from "react";
import ModalContainer from "../components/Common/Modal/ModalContainer";
import { CreateCouponModel } from "../components/UiComponents/CouponComponents/CreateCouponModel";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useCouponStore } from "../../store/main/CouponStore";
import { Constants } from "../../common/Constants";
import { CouponsList } from "../../domain/models/coupons/fetch-coupons-list";
import { useCommonStore } from "../../store/main/CommonStore";
import { useDoctorStore } from "../../store/main/DoctorStore";

const filterItems = [
  {
    label: "Expires First",
    onClick: () => {},
    icon: <AccessTimeIcon />,
  },
  {
    label: "InActive",
    onClick: () => {},
    icon: <AccessTimeIcon />,
  },
];

interface FormErrors {
  [key: string]: string | null;
}

interface FormValues {
  couponCode: string;
  couponName: string;
  couponDescription: string;
  value: string;
  percentage: string;
  minQty: string;
  startDate: Dayjs | null;
  expiryDate: Dayjs | null;
  agreeToTerms: boolean;
}

const initialState = {
  couponCode: "",
  couponName: "",
  couponDescription: "",
  value: "",
  percentage: "",
  minQty: "",
  startDate: null,
  expiryDate: null,
  agreeToTerms: false,
};

export const CouponPage = () => {
  const [openCreateCouponModal, setOpenCreateCouponModal] =
    useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [selectedAddProduct, setSelectedAddProduct] = useState<any[]>([]);
  const [valueField, setvalueField] = useState<boolean>(false);
  const [percentageField, setpercentageField] = useState<boolean>(false);

  const closeModal = () => {
    useCouponStore.getState().productFilters.q = "";
    useCouponStore.getState().selectedCoupon =
      useCouponStore.getState().couponList.length > 0
        ? useCouponStore.getState().couponList[0]
        : ({} as CouponsList);
    useCouponStore
      .getState()
      .fetchCouponCustomerList(useCouponStore.getState().selectedCoupon.id);
    setFormValues(initialState);
    setpercentageField(false);
    setvalueField(false);
    useCommonStore.getState().resetError();
    setOpenCreateCouponModal(false);
    setErrors({});
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    Object.keys(formValues).forEach((key) => {
      const isFieldDisabled =
        (key === "percentage" && percentageField) ||
        (key === "value" && valueField);
      if (!formValues[key as keyof FormValues] && !isFieldDisabled) {
        if (key !== "expiryDate") {
          newErrors[key] = "This field cannot be empty";
        }
      } else {
        newErrors[key] = null;
      }
    });
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleChange = (field: string, value: string | boolean) => {
    if (field === "value") {
      setvalueField(false);
      if (value === "") {
        setpercentageField(false);
      } else {
        setpercentageField(true);
      }
    } else if (field === "percentage") {
      if (Number(value) > 100) {
        return;
      }
      if (value === "") {
        setvalueField(false);
      } else {
        setvalueField(true);
      }
      setpercentageField(false);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: null,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const discount_type = formValues.percentage ? "percentage" : "flat";
    const discount = formValues.percentage
      ? formValues.percentage
      : formValues.value;

    if (validateForm()) {
      const fromDate = moment(
        (formValues.startDate as Dayjs).toString()
      ).format(Constants.YEAR_MONTH_DATE_FORMAT);
      let toDate;
      if (formValues.expiryDate) {
        toDate = moment((formValues.expiryDate as Dayjs).toString()).format(
          Constants.YEAR_MONTH_DATE_FORMAT
        );
      }
      useCouponStore
        .getState()
        .createCoupon(
          formValues.couponCode,
          formValues.couponName,
          formValues.couponDescription,
          "cod",
          formValues.minQty,
          discount_type,
          discount,
          fromDate,
          toDate ? toDate : null,
          "",
          selectedAddProduct
        );
      closeModal();
    }
  };

  const handleAddCallback = (data: any) => {
    setSelectedAddProduct(data);
  };

  useEffect(() => {
    useCouponStore
      .getState()
      .fetchCouponsList(1, 10, false, useCouponStore.getState().filter);
    useCouponStore.getState().filter = {
      q: "",
      expire_date: "",
    };
    useDoctorStore.getState().doctorFilters = {
      q: "",
      hq_code: "",
    };
  }, []);

  return (
    <Layout>
      <Grid container m={1} pr={1}>
        <Grid item xs={3}>
          <Stack gap={3}>
            <Typography variant="h5" fontWeight={500}>
              Coupon Overview
            </Typography>
            <Chip
              sx={{ width: "189px", cursor: "pointer", py: 2 }}
              variant="outlined"
              size="small"
              label={<Typography>Create new coupon</Typography>}
              icon={<CreateNewFolderIcon />}
              onClick={() => {
                setOpenCreateCouponModal(true);
                useCouponStore.getState().getDepartmentsList();
                useCouponStore.getState().getOrganizationsList();
                useCouponStore.getState().selectedCoupon = {} as CouponsList;
              }}
            />
            {openCreateCouponModal && (
              <ModalContainer
                title={"Add New Coupon"}
                open={openCreateCouponModal}
                handleClose={() => {
                  closeModal();
                }}
                handleAction={() => {}}
                children={
                  <CreateCouponModel
                    onClose={closeModal}
                    formValues={formValues}
                    handleChange={handleChange}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleCallBack={handleAddCallback}
                    valueField={valueField}
                    percentageField={percentageField}
                    selectedProducts={selectedAddProduct}
                  />
                }
              />
            )}

            <CouponFilters filterItems={filterItems} />
            <Box sx={{ overflowY: "scroll" }} height="58vh" id="coupon-page">
              <CouponListing />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={9}
          paddingTop={7}
          paddingLeft={2}
          sx={{
            overflowY: "scroll",
            height: "93vh",
            backgroundColor: "#f9f9f9",
          }}
        >
          <TabComponent
            tabs={[
              {
                label: "Coupon Information",
                content: <CouponInformation />,
              },
              {
                label: "List of Doctors",
                content: <DoctorList />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
