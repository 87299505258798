import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {AUTH_HEADER, COUPON_TOKEN_KEY, COUPON_BASE_URL_KEY, DISCOUNT_COUPON_API_URL } from "../../../base";
import {Endpoints} from "../../../domain/Endpoints";
import { FetchCouponRequestForCustomerType } from "../../../domain/models/discount-approvals/fetch-coupon-request-for-customer";
import { RemoteFetchCouponRequestForCustomer } from "../../../data/usecases/discount-approvals/remote-fetch-coupon-request-for-customer.tsx";
import { useDiscountApprovalStore } from "../../main/DiscountApprovalStore";

export type FiltersType={
  q?:string
  is_approval_required: number,
}

interface FetchCouponRequestForCustomerSliceInterface {
  fetchCouponRequestForCustomer: Function;
  fetchCouponRequestForCustomerState: FetchState;
  couponRequestForCustomerList: FetchCouponRequestForCustomerType[];
  currentCouponRequestForCustomerPage: number;
  lastCouponRequestForCustomerPage: number;
  totalCouponRequestForCustomer: number;
  handlePageNumberCouponRequestForCustomer: Function;
couponRequestForCustomeFilters: FiltersType;
}

let initialFilterPayload = {
  q: "",
  is_approval_required:1,
};

const initialStates = {
  fetchCouponRequestForCustomerState: FetchState.DEFAULT,
  currentCouponRequestForCustomerPage: 1,
  lastCouponRequestForCustomerPage: 2,
  totalCouponRequestForCustomer:0,
  couponRequestForCustomerList: [],
  couponRequestForCustomeFilters: initialFilterPayload,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCouponRequestForCustomerSlice: StateCreator<
FetchCouponRequestForCustomerSliceInterface
> = (set, get) => ({
    ...initialStates,
    fetchCouponRequestForCustomer:async(id:string, page:number, length: number)=>{
      const token = storage.get(
        COUPON_TOKEN_KEY
      );
      // const BASE_URL = storage.get(COUPON_BASE_URL_KEY);
      
        axiosHttpClient.setAuthHeaders({   [AUTH_HEADER]:atob(token)})
       
        const remoteFetchCouponRequestForCustomer = new RemoteFetchCouponRequestForCustomer(
          `${DISCOUNT_COUPON_API_URL}${Endpoints.COUPON_REQUEST_FOR_CUSTOMER}`,
          axiosHttpClient
        );
        try {
          set(() => ({ 
            fetchCouponRequestForCustomerState: FetchState.LOADING,
          
            }));
            let params={
              page:page,
              length:length,
              is_approval_required:get().couponRequestForCustomeFilters.is_approval_required,
              q: get().couponRequestForCustomeFilters.q
            }
            let result = await remoteFetchCouponRequestForCustomer.fetch(id, params);
            if(result.current_page){
              if(result.data.length==0){
                useDiscountApprovalStore.getState().handleRemoveRecord(id)
              }
                set(() => ({ 
                  fetchCouponRequestForCustomerState: FetchState.SUCCESS,
                    currentCouponRequestForCustomerPage: result.current_page,
                    lastCouponRequestForCustomerPage:result.last_page,
                    totalCouponRequestForCustomer: result.total  ,
                    couponRequestForCustomerList: result.data
                  }));

            }
        }
        catch{
          set(() => ({ 
            fetchCouponRequestForCustomerState: FetchState.LOADING,
            }));
        }
        
    },
    handlePageNumberCouponRequestForCustomer: (pageNo: number) => {
      set(() => ({ currentCouponRequestForCustomerPage: pageNo }));
    },

});
