import { Chip, Stack, Typography } from "@mui/material";

type ButtonType = {
  label?: string | React.ReactElement<any>;
  icon?: React.ReactElement<any>;
  action: Function;
};

type Props = {
  buttons: ButtonType[];
};

export const DataGridTools: React.FC<Props> = ({ buttons }) => {
  return (
    <Stack mb={2}>
      <Stack direction={"row"} justifyContent={"end"} gap={1}>
        {buttons &&
          buttons.map((data, index) => (
            <Chip
              variant="outlined"
              sx={{ padding: 2, bgcolor: "#F7F8FA" }}
              size="small"
              key={index}
              icon={data?.icon}
              label={<Typography>{data?.label}</Typography>}
              onClick={() => data?.action()}
            ></Chip>
          ))}
      </Stack>
    </Stack>
  );
};
