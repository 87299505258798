export const AUTH_HEADER: string = process.env.REACT_APP_AUTH_HEADER || '';
export const AUTH_HEADER_PRODUCT_SERVICE: string = process.env.REACT_APP_AUTH_HEADER || '';
export const BASE_API_URL: string = process.env.REACT_APP_BASE_API_URL || '';
export const AUTH_TOKEN_KEY: string = process.env.REACT_APP_AUTH_TOKEN_KEY || '';
export const STAGING_DOC_URL: string = process.env.REACT_APP_STAGING_DOC_URL || '';
export const STAGIN_AUTH_URL: string = process.env.REACT_APP_STAGIN_AUTH_URL || '';
export const STAGIN_PRODUCT_URL: string = process.env.REACT_APP_STAGIN_PRODUCT_URL || '';
export const GA_AUTH_HEADER: string = process.env.REACT_APP_GA_AUTH_HEADER || '';
export const GA_PRODUCT_HEADER: string = process.env.REACT_APP_GA_PRODUCT_HEADER || '';
export const GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY: string = process.env.REACT_APP_GA_PRODUCT_SERVICE_HEADER_TOKEN_KEY || '';
export const GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY: string = process.env.REACT_APP_GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_KEY || '';
export const GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY: string = process.env.REACT_APP_GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_KEY || '';
export const TOKEN_KEY: string = process.env.REACT_APP_TOKEN_KEY || '';
export const GA_PRODUCT_SERVICE_HEADER_TOKEN_VALUE: string = process.env.REACT_APP_GA_PRODUCT_SERVICE_HEADER_TOKEN_VALUE || '';
export const GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_VALUE: string = process.env.REACT_APP_GA_PRODUCT_SERVICE_AUTH_HEADER_TOKEN_VALUE || '';
export const GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_VALUE: string = process.env.REACT_APP_GA_DOCTOR_SERVICE_AUTH_HEADER_TOKEN_VALUE || '';
export const COUPON_TOKEN_KEY: string = process.env.REACT_APP_COUPON_TOKEN_KEY || '';
export const COUPON_BASE_URL_KEY:string = process.env.REACT_APP_COUPON_BASE_URL_KEY || '';
export const GA_DEPT_AUTH_HEADER: string = process.env.REACT_APP_GA_DEPT_AUTH_HEADER || '';
export const HEADQUARTED_API_KEY: string = process.env.REACT_APP_GA_DEPT_AUTH_HEADER || '';
export const DISCOUNT_COUPON_API_URL: string = process.env.REACT_APP_DISCOUNT_COUPON_API_URL ||'';
