import { HttpConstants } from "../../protocols/http/http-constants";
import { UpdateRequestStatus } from "../../../domain/usages/discount-approvals/update-request-status";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteUpdateRequestStatus implements UpdateRequestStatus {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(id:string,params: UpdateRequestStatus.Params ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
   
      return httpResponse.data;
    }
  }

