import { Box, Stack, Typography } from "@mui/material";
import { TwoLayerText } from "../../Common/Typography/TwoLayerText";
import { TableComponent } from "../../Common/Table/TableComponent";
import { DataGridTools } from "./DataGridTools";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ModalContainer from "../../Common/Modal/ModalContainer";
import { LoadingModal } from "../../Common/Modal/LoadingModal";
import { ConfirmationModal } from "../../Common/Modal/ConfirmationModal";
import { AddNewDoctorModal } from "./AddNewDoctorModal";
import { useCouponStore } from "../../../../store/main/CouponStore";
import { GridColDef } from "@mui/x-data-grid";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import InfoSkeleton from "../../Common/Skeleton/InfoSkeleton";

const doctorsColumns: GridColDef[] = [
  { field: "customer_uuid", headerName: "Doctor ID", flex: 1 },
  { field: "name", headerName: "Doctor Name", flex: 1 },
  { field: "speciality", headerName: "Speciality", flex: 1 },
];

export const DoctorList = () => {
  const {
    couponCustomersList,
    selectedCoupon,
    deleteCustomers,
    loadingCustomerDeletion,
    loadingCustomerAddition,
    addCustomers,
    fetchState,
  } = useCouponStore();
  const [selectedDeleteDoctor, setSelectedDeleteDoctor] = useState<any[]>([]);
  const [selectedAddDoctor, setSelectedAddDoctor] = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openAddDoctorModal, setOpenAddDoctorModal] = useState<boolean>(false);

  const handleDeleteCallback = (data: any) => {
    setSelectedDeleteDoctor(data);
  };

  const handleAddCallback = (data: any) => {
    const modified_data = data.map((doctor: any) => ({
      _id: doctor._id,
      full_name: doctor.full_name,
      speciality: doctor.speciality_names,
    }));
    setSelectedAddDoctor(modified_data);
  };

  const handleOpenAddDoctorCalleback = () => {
    setOpenAddDoctorModal(true);
  };

  const buttons = [
    {
      label: "Map new Doctor",
      action: () => {
        handleOpenAddDoctorCalleback();
      },
      icon: <AddIcon />,
    },
    ...(selectedDeleteDoctor.length > 0
      ? [
          {
            label: <Typography color={"error"}>Delete</Typography>,
            icon: <DeleteIcon color="error" />,
            action: () => {
              setOpenDeleteModal(true);
            },
          },
        ]
      : []),
  ];

  return (
    <>
      {fetchState === FetchState.LOADING && <InfoSkeleton />}
      {fetchState === FetchState.SUCCESS && (
        <Box>
          <Stack direction={"row"} gap={4} mb={2}>
            <TwoLayerText
              containerStyle={{
                borderRight: "1px solid lightgrey",
                padding: 2,
              }}
              headerText={"In current coupon"}
              subText={{
                colouredText: couponCustomersList.length.toString(),
                text: "doctors",
              }}
            />
            <TwoLayerText
              containerStyle={{
                borderRight: "1px solid lightgrey",
                padding: 2,
              }}
              headerText={"In current coupon"}
              subText={{
                colouredText:
                  selectedCoupon.app_code &&
                  selectedCoupon.products.length.toString(),
                text: "products",
              }}
            />{" "}
          </Stack>
          {selectedCoupon.status === "active" && (
            <DataGridTools buttons={buttons} />
          )}
          {couponCustomersList.length > 0 ? (
            <TableComponent
              tableData={couponCustomersList}
              columns={doctorsColumns}
              limit={5}
              handleSelectCallback={handleDeleteCallback}
            />
          ) : (
            <Typography>No Doctors Associated to this Coupon</Typography>
          )}
          <ConfirmationModal
            title={"Delete selected doctors?"}
            open={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            handleAction={() => {
              const uuids = selectedDeleteDoctor.map(
                (doctor) => doctor.customer_uuid
              );
              deleteCustomers(selectedCoupon.id, uuids);
              setOpenDeleteModal(false);
            }}
            primaryButtonText={"Confirm Delete"}
            secondaryButtonText={"Cancel"}
            modalContent={
              "Please confirm to delete the selected doctors from the coupon #990-132"
            }
          />
          <LoadingModal
            open={loadingCustomerDeletion || loadingCustomerAddition}
            title={"Loading..."}
            modalContent={"Please wait while your request is proccesing"}
          />
          {openAddDoctorModal && (
            <ModalContainer
              title={"Add New Doctor"}
              open={openAddDoctorModal}
              handleClose={() => {
                useDoctorStore.getState().doctorFilters.q = "";
                setOpenAddDoctorModal(false);
              }}
              handleAction={() => {
                addCustomers(selectedCoupon.id, selectedAddDoctor);
                setOpenAddDoctorModal(false);
              }}
              children={
                <AddNewDoctorModal handleCallBack={handleAddCallback} />
              }
              primaryButtonText={
                selectedAddDoctor.length > 0 ? "Add Doctors" : ""
              }
            />
          )}
        </Box>
      )}
      {fetchState === FetchState.ERROR && (
        <Typography>Something went wrong</Typography>
      )}
    </>
  );
};
