import { create } from "zustand";
import { useFetchcouponListSlice } from "../slices/Coupons/FetchCouponsListSlice";
import { useDeleteProductsSlice } from "../slices/Coupons/DeleteProductsSlice";
import { useFetchCouponCustomersListSlice } from "../slices/Coupons/FetchCouponCustomersListSlice";
import { useDeleteCustomersSlice } from "../slices/Coupons/DeleteCustomersSlice";
import { useCreateCouponSlice } from "../slices/Coupons/CreateCouponSlice";
import { useAddCustomersSlice } from "../slices/Coupons/AddCustomersSlice";
import { useAddProductsSlice } from "../slices/Coupons/AddProductsSlice";
import { useFetchProductsListSlice } from "../slices/Coupons/FetchProductsListSlice";
import { useGetDepartmentsListSlice } from "../slices/Coupons/GetDepartmentsListSlice";
import { useGetOrganizationsListSlice } from "../slices/Coupons/GetOrganizationsListSlice";
import { useGetHeadquartersListSlice } from "../slices/Coupons/GetHeadquartersListSlice";
import { useDeactivateCouponSlice } from "../slices/Coupons/DeactivateCouponSlice";

interface CouponStoreType
  extends ReturnType<typeof useFetchcouponListSlice>,
    ReturnType<typeof useDeleteProductsSlice>,
    ReturnType<typeof useFetchCouponCustomersListSlice>,
    ReturnType<typeof useDeleteCustomersSlice>,
    ReturnType<typeof useCreateCouponSlice>,
    ReturnType<typeof useAddCustomersSlice>,
    ReturnType<typeof useAddProductsSlice>,
    ReturnType<typeof useGetDepartmentsListSlice>,
    ReturnType<typeof useGetOrganizationsListSlice>,
    ReturnType<typeof useGetHeadquartersListSlice>,
    ReturnType<typeof useDeactivateCouponSlice>,
    ReturnType<typeof useFetchProductsListSlice> {}

export const useCouponStore =
  create<CouponStoreType>((...coupon) => ({
    ...useFetchcouponListSlice(...coupon),
    ...useDeleteProductsSlice(...coupon),
    ...useFetchCouponCustomersListSlice(...coupon),
    ...useDeleteCustomersSlice(...coupon),
    ...useCreateCouponSlice(...coupon),
    ...useAddCustomersSlice(...coupon),
    ...useAddProductsSlice(...coupon),
    ...useFetchProductsListSlice(...coupon),
    ...useGetDepartmentsListSlice(...coupon),
    ...useGetOrganizationsListSlice(...coupon),
    ...useGetHeadquartersListSlice(...coupon),
    ...useDeactivateCouponSlice(...coupon),
  }));
