import Skeleton from "@mui/material/Skeleton";
import { Stack, Card } from "@mui/material";

export default function SkeletonCard() {
  return (
    <Card
      sx={{
        minWidth: 200,
        maxWidth: 250,
        borderRadius: "12px",
        padding: 1,
        boxShadow: "none",
        border: "1px solid #D0CFCF",
      }}
    >
      <Stack direction={"row"} gap={3}>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Stack>
      <Stack>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Stack>
    </Card>
  );
}
