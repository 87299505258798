import { Chip, Stack, Skeleton } from "@mui/material";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useCouponStore } from "../../../../store/main/CouponStore";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { DoctorFilters } from "../../../../store/slices/Doctors/FetchDoctorsListSlice";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import { MultipleSelectCheckmarks } from "../../Common/InputComponents/MultipleSelectCheckmarks";
import { useDoctorStore } from "../../../../store/main/DoctorStore";
import { SearchField } from "../../Common/InputComponents/SearchField";

type SelectedFilters = {
  [key: string]: boolean;
};

const filterItems = [
  {
    label: "Headquarter",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Region",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Area",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Zone",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
  {
    label: "Country",
    onClick: () => {},
    icon: <PinDropIcon />,
  },
];

export const DoctorFilter = () => {
  const { applyDoctorFilters } = useDoctorStore();
  const {
    getHeadquartersListState,
    headquartersList,
    applyHeadquartersFilter,
  } = useCouponStore();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    Headquarter: false,
    Region: false,
    Country: false,
    Zone: false,
    Area: false,
  });
  const [filters, setFilters] = useState<DoctorFilters>({
    q: "",
    hq_code: "",
  });

  const handleFilterClick = (label: string) => {
    setSelectedFilters((prevSelected) => {
      const updatedFilters = { ...prevSelected, [label]: !prevSelected[label] };

      const selectedKeys = Object.keys(updatedFilters).filter(
        (key) => updatedFilters[key] === true
      );
      applyHeadquartersFilter({
        org_id: 2,
        department_id: 4,
        types: selectedKeys,
      });
      return updatedFilters;
    });
  };

  useEffect(() => {
    applyHeadquartersFilter({});
  }, []);

  const handleSearch = (e: any) => {
    applyDoctorFilters({ ...filters, q: e.target.value });
    setFilters({ ...filters, q: e.target.value });
  };

  const handleHQChange = (e: any) => {
    applyDoctorFilters({ ...filters, hq_code: e?.code });
    setFilters({ ...filters, hq_code: e?.code });
  };

  const Searchdebounce = debounce(handleSearch, 1000);

  return (
    <>
      {getHeadquartersListState === FetchState.LOADING && (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={230}
          height={53}
        />
      )}
      {getHeadquartersListState === FetchState.SUCCESS && (
        <MultipleSelectCheckmarks
          width={230}
          names={headquartersList}
          onSelectCallback={handleHQChange}
        />
      )}
      <Stack
        direction={"row"}
        flexWrap="wrap"
        gap={1}
        justifyContent={"flex-start"}
        width={"80%"}
      >
        {filterItems.map((data, index) => (
          <Chip
            icon={data.icon}
            sx={{ width: "80px", py: 1 }}
            key={index}
            size={"small"}
            label={data.label}
            color={selectedFilters[data.label] === true ? "primary" : "default"}
            onClick={() => {
              handleFilterClick(data.label);
            }}
          />
        ))}
      </Stack>
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="flex-start"
        width={"100%"}
      >
        <SearchField
          handleChange={Searchdebounce}
          placeholder="Search...."
          startAdornment={<SearchIcon />}
        />
      </Stack>
    </>
  );
};
